import { Alert, Checkbox, Col, Form, Row, Switch, Tooltip } from "antd";
import { AiFillInfoCircle } from "react-icons/ai";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import UpgradeTag from "src/components/lib/upgrade-tag";
import { useSelectorTS } from "src/hooks/redux-ts";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { IShow } from "redcircle-types";
import { tierLevel } from "src/reducers/types";
import CategoryTreeSelect from "./category_tree_select";

export default function RAPSettingsFormProgrammatic({ show }: { show?: IShow }) {
  const form = Form.useFormInstance();
  const isProgrammaticEnabled = Form.useWatch(["programmaticAdSettings", "enabled"], form);
  const safetyPosture = Form.useWatch(["programmaticAdSettings", "safetyPosture"], form);

  const { tier, user } = useSelectorTS((state) => state?.user);
  const userCanConfigureProgrammatic = minimumAllowedTier(tierLevel.pro, tier, true);
  const userCanConfigureStrictProtection = user?.userAttributes?.canConfigureSafetyPosture;
  const isStrictProtectionEnabled = safetyPosture === "strict";

  return (
    <div>
      <Form.Item name={["programmaticAdSettings", "enabled"]} valuePropName="checked">
        <Checkbox>
          <div className="flex-row-container align-center">
            <h3 className="m-rxxxs">Programmatic Ads</h3>
            <Tooltip title="Targeted ads that are pre-recorded and automatically inserted into your episodes using dynamic insertion.">
              <AiFillInfoCircle />
            </Tooltip>
          </div>
        </Checkbox>
      </Form.Item>

      {isProgrammaticEnabled && (
        <div className="p-ls">
          <h5>Select your show's primary category*</h5>
          <p>Your primary category will help brands target their ad to their ideal audience.</p>
          <Form.List name={["categoryUUIDs"]}>
            {(fields) =>
              [fields[0]].map((field) => (
                <Form.Item
                  {...field}
                  key={field.key}
                  rules={[
                    {
                      required: isProgrammaticEnabled,
                      message: "Please select a category",
                    },
                  ]}>
                  <CategoryTreeSelect />
                </Form.Item>
              ))
            }
          </Form.List>

          <div className="flex-row-container align-center m-txs">
            <h5 className="m-rxxs">Positions where Programmatic Ads will play</h5>
            {!userCanConfigureProgrammatic && <UpgradeTag />}
          </div>
          <p>We recommend all positions (Pre-Roll, Mid-Roll, and Post-Roll) to maximize revenue.</p>

          <Tooltip
            title={
              !userCanConfigureProgrammatic
                ? "Upgrade to Pro to configure your programmatic ads"
                : ""
            }>
            <Row>
              <Col xs={8}>
                <label className="redcircle-form-label">Pre-Roll</label>
                <Form.Item
                  name={["programmaticAdSettings", "preRollEnabled"]}
                  valuePropName="checked">
                  <Switch disabled={!userCanConfigureProgrammatic} />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <label className="redcircle-form-label">Mid-Roll</label>
                <Form.Item
                  name={["programmaticAdSettings", "midRollEnabled"]}
                  valuePropName="checked">
                  <Switch disabled={!userCanConfigureProgrammatic} />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <label className="redcircle-form-label">Post-Roll</label>
                <Form.Item
                  name={["programmaticAdSettings", "postRollEnabled"]}
                  valuePropName="checked">
                  <Switch disabled={!userCanConfigureProgrammatic} />
                </Form.Item>
              </Col>
            </Row>
          </Tooltip>

          {userCanConfigureStrictProtection && (
            <>
              <div className="flex-row-container align-center m-txs">
                <h5 className="m-rxxs">Strict Protection</h5>
                {!userCanConfigureProgrammatic && <UpgradeTag />}
              </div>
              <p>
                If enabled, only ads reviewed by the RedCircle team for category accuracy will be
                inserted. This setting will significantly reduce your revenue.
              </p>
              <label className="redcircle-form-label">Strict Protection</label>
              <Form.Item
                name={["programmaticAdSettings", "safetyPosture"]}
                valuePropName="checked"
                getValueFromEvent={(e) => (e ? "strict" : "")}
                className="m-bxs">
                <Switch
                  disabled={!userCanConfigureProgrammatic || !userCanConfigureStrictProtection}
                />
              </Form.Item>
              {isStrictProtectionEnabled && (
                <Alert
                  message="Warning: enabling strict protection may decrease your programmatic revenue by 90% or more."
                  type="warning"
                  showIcon
                />
              )}
            </>
          )}
        </div>
      )}

      {!isProgrammaticEnabled && (
        <EmptyStateBlock>
          <strong className="redcircle-form-label">WHAT ARE PROGRAMMATIC ADS?</strong>
          Targeted ads that are pre-recorded and automatically inserted into your episodes using
          dynamic insertion.
        </EmptyStateBlock>
      )}
    </div>
  );
}
