import { calculateDefaultCPMsForShow } from "src/lib/rap";
import { IProgrammaticAdSettings, IShow, User } from "redcircle-types";

export const getDefaultAdvertisementSettings = (show: IShow, user?: User) => {
  const { hostRead, preRecorded, spotRate } = calculateDefaultCPMsForShow(show);
  return {
    hosts: [{ fullName: { firstName: user?.firstName, lastName: user?.lastName } }],

    excludedCategories: [],
    excludedBrandInstanceUUIDs: [],

    isHostReadEnabled: true,
    hostReadPreRollCPM: hostRead.discount,
    hostReadMidRollCPM: hostRead.default,
    hostReadPostRollCPM: hostRead.discount,

    isPreRecordedEnabled: true,
    preRecordedPreRollCPM: preRecorded.discount,
    preRecordedMidRollCPM: preRecorded.default,
    preRecordedPostRollCPM: preRecorded.discount,

    automaticallyCalculateSpotRates: true,
    preRollSpotRate: spotRate.discount,
    midRollSpotRate: spotRate.default,
    postRollSpotRate: spotRate.discount,
  };
};

export const getDefaultListenerDemographics = () => {
  const listenerDemographics = {
    genderBreakdown: {
      male: 0,
      female: 0,
      unknown: 0,
      nonBinary: 0,
    },
    ageBuckets: ["0-17", "18-22", "23-27", "28-34", "35-44", "45-59", "60-150", "0-0"].map(
      (label) => {
        const [minAge, maxAge] = label.split("-").map((a) => parseInt(a));
        return {
          minAge,
          maxAge,
          label,
          percentage: 0,
        };
      }
    ),
  };
  return listenerDemographics;
};

export const getDefaultProgrammaticSettings = (show: IShow) => {
  return {
    enabled: !show.isRemote,
    preRollEnabled: true,
    midRollEnabled: true,
    postRollEnabled: true,
    safetyPosture: "",
  } as IProgrammaticAdSettings;
};
