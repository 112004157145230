import moment from "moment";
import { useRef, useState } from "react";
import { Table } from "redcircle-ui";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import ExternalLink from "src/components/lib/external_link";
import Loading from "src/components/lib/loading";
import { mediaFileReady } from "src/components/pages/dynamic_insertion/dynamic_insertion_util";
import { secondsToString } from "src/lib/numbers";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { CustomAudio } from "src/reducers/custom_audio";
import { tierLevel } from "redcircle-types";
import styles from "./custom_audio.module.scss";
import CustomAudioBlocks from "./custom_audio_blocks";
import CustomAudioImpressionProgress from "./custom_audio_impression_progress";
import MediaPlayer from "./custom_audio_media_player";
import PlayPause from "./custom_audio_play_pause";
import { ColumnType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";

interface IProps {
  customAudios: Record<string, CustomAudio>;
  query: string;
  tier: any;
  onEditAudio: (record: any) => void;
  isLoading?: boolean;
}

const keyBasedSort = (key: string) => {
  return (a: any, b: any) => a[key] - b[key];
};

const descendingSortOrder: SortOrder[] = ["descend", "ascend"];

export default function CustomAudioTable({
  customAudios,
  query,
  tier,
  onEditAudio,
  isLoading,
}: IProps) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const expandRow = (key: string) => {
    setExpandedRowKeys([...expandedRowKeys, key]);
  };

  const collapseRow = (key: string) => {
    setExpandedRowKeys(expandedRowKeys.filter((rowKey: string) => rowKey !== key));
  };

  const audioRefs = useRef<Record<string, HTMLAudioElement | null>>({});

  const [audioProgress, setAudioProgress] = useState<Record<string, number>>({});

  const handlePlayPause = (recordKey: string) => {
    const audioElement = audioRefs.current[recordKey];
    if (audioElement?.paused) {
      expandRow(recordKey);
      audioElement.play();
    } else {
      audioElement?.pause();
    }
  };

  const updateAudioProgress = (recordKey: string) => {
    const progress = audioRefs.current[recordKey]?.currentTime || 0;
    setAudioProgress({ ...audioProgress, [recordKey]: progress });
  };

  let columns: ColumnType<CustomAudio>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text: string, record: any) => {
        return (
          <>
            <strong>{text}</strong>
            {record.mediaFile && (
              <>
                <br />
                <span>{record.mediaFile.fileName}</span>
              </>
            )}
          </>
        );
      },
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Blocks",
      key: "blocks",
      dataIndex: "blocks",
      render: (_: string, record: any) => {
        return <CustomAudioBlocks customAudio={record} />;
      },
      sorter: keyBasedSort("blocks"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "Impressions",
      key: "insertions",
      dataIndex: "insertions",
      render: (_: string, record: any) => {
        if (record.insertions == null) {
          return <Loading size="small" />;
        }

        return (
          <CustomAudioImpressionProgress
            impressionCap={record.maxInsertions}
            impressions={record.insertions}
          />
        );
      },
      sorter: keyBasedSort("impressions"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "Start",
      key: "startAt",
      dataIndex: "startAt",
      render: (startAt: any) => {
        if (!startAt) {
          return "-";
        }

        return (
          <>
            <span>{moment.unix(startAt).format("L")}</span>
            <br />
            <span>{moment.unix(startAt).format("LT")}</span>
          </>
        );
      },
      sorter: keyBasedSort("startAt"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "End",
      key: "endAt",
      dataIndex: "endAt",
      render: (endAt: any) => {
        if (!endAt) {
          return "-";
        }

        return (
          <>
            <span>{moment.unix(endAt).format("L")}</span>
            <br />
            <span>{moment.unix(endAt).format("LT")}</span>
          </>
        );
      },
      sorter: keyBasedSort("endAt"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "URL",
      key: "trackingURL",
      dataIndex: "trackingURL",
      render: (text: string) => (text ? "Yes" : "-"),
      sorter: (a: any) => (a.trackingURL ? -1 : 1),
    },
    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      defaultSortOrder: "descend",
      render: (createdAt: any) => moment.unix(createdAt).format("L"),
      sorter: keyBasedSort("createdAt"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      render: (_: any, record: any) => {
        if (!mediaFileReady(record.mediaFile)) {
          return <Loading size="small" />;
        }

        const duration = record.mediaFile.duration || 0;

        return secondsToString(duration / 1000);
      },
      sorter: keyBasedSort("duration"),
      sortDirections: descendingSortOrder,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_text: string, record) => {
        if (record.isPaused) {
          return <ExclusiveTag className="tag-sent">Paused</ExclusiveTag>;
        }

        return <ExclusiveTag className="tag-running">Active</ExclusiveTag>;
      },
      sorter: (a) => (a.isPaused ? 1 : -1),
    },
    {
      title: "Audio",
      key: "audio",
      dataIndex: "audio",
      render: (_: any, record: any) => {
        if (!mediaFileReady(record.mediaFile)) {
          return <Loading size="small" />;
        }

        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
              handlePlayPause(record.uuid);
            }}>
            <audio
              ref={(element) => (audioRefs.current[record.uuid] = element)}
              onTimeUpdate={() => updateAudioProgress(record.uuid)}>
              <source src={record.mediaFile.convertedURL} type={record.mediaFile.fileType} />
            </audio>
            <PlayPause audioRef={audioRefs.current[record.uuid]} />
          </div>
        );
      },
    },
  ];

  // Hide columns for OG/Core
  const hideColumns = !minimumAllowedTier(tierLevel.pro, tier, false);
  const hiddenColumns = ["startAt", "endAt", "trackingURL"];
  if (hideColumns) {
    columns = columns.filter((column: any) => !hiddenColumns.includes(column.key));
  }

  let data: CustomAudio[] = Object.values(customAudios);

  if (isLoading) return <Loading />;
  if (data.length === 0 && !isLoading) {
    return (
      <EmptyStateBlock>
        <span>
          Add your own custom audio to dynamically insert intros, outros, or your own promotions
          into your episodes.
          <ExternalLink
            className="m-lxxs"
            href="https://support.redcircle.com/dynamic-insertion-cookbook">
            Learn More
          </ExternalLink>
        </span>
      </EmptyStateBlock>
    );
  }

  // Search
  data = data.filter(
    (record: any) =>
      record.name?.toLowerCase()?.includes(query.toLowerCase()) ||
      record.mediaFile?.fileName?.toLowerCase()?.includes(query.toLowerCase())
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <MediaPlayer
            audioRef={audioRefs.current[record.uuid]}
            progress={audioProgress[record.uuid]}
            recordKey={record.uuid}
            onClose={collapseRow}
            onPlayPause={handlePlayPause}
          />
        ),
        expandedRowKeys,
        showExpandColumn: false,
      }}
      pagination={{ hideOnSinglePage: true, position: ["bottomCenter"] }}
      rowClassName={styles.customAudioTableRow}
      rowKey="uuid"
      onRow={(record: any) => {
        return {
          onClick: () => onEditAudio(record),
        };
      }}
    />
  );
}
