import dayjs from "dayjs";
import { useContext } from "react";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";
import { useSelectorTS } from "src/hooks/redux-ts";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerSuccessPage() {
  const { campaign, scripts } = useContext(CampaignSchedulerContext);

  // ScriptDueDate is only used by Success Page. After payment confirmation page, the campaignItem(s) in draft state are updated
  // to sent state and scriptDueDate is set. Grabbing the scriptDueBy from first campaignItem found with scriptDueBy prop is
  // number. The selector will update once a campaignItem with a scriptDueBy prop is set to a number.
  const scriptDueDate = useSelectorTS((state) => {
    const campaignItemUUIDs = campaign?.uuid
      ? state?.campaignItems?.campaignItemByCampaignUUID?.[campaign?.uuid]
      : [];

    return campaignItemUUIDs
      ?.map((campaignItemUUID) => state?.campaignItems?.campaignItems?.[campaignItemUUID])
      ?.find((campaignItem) => typeof campaignItem?.scriptDueBy === "number")?.scriptDueBy;
  });

  const dueDateText = scriptDueDate ? dayjs.unix(scriptDueDate) : undefined;
  const daysFromNow = scriptDueDate ? dayjs.unix(scriptDueDate).diff(dayjs(), "days") : undefined;

  const renderScriptInCampaign = () => (
    <>
      <span className="redcircle-form-label">Script Due</span>
      <p className="m-bs">
        You will have to add and assign a script to this campaign. Scripts can be added from the{" "}
        <strong>Scripts</strong> tab on your campaign dashboard.
      </p>
    </>
  );

  return (
    <WarningPanelv2>
      <h3 className="m-bxs">Next Steps</h3>
      {(!scripts || scripts.length === 0) && renderScriptInCampaign()}
      <span className="redcircle-form-label">Follow up</span>
      <p>
        As podcasts accept your campaign, be sure to <strong>Download Podcast Info</strong> for
        important information that you will need.
      </p>
      <p className="m-bs">
        Depending on your campaign, this could include info like mailing addresses so you can ship
        free products, unique show promo codes so you can add them in your system, and which
        redemption promo codes were matched to which hosts.
      </p>

      <span className="redcircle-form-label">Reminder</span>
      <p>
        The <strong>Download Podcast Info</strong> list will update as podcasts accept, so continue
        to check back and re-export as needed so you don’t miss any show information.
      </p>
    </WarningPanelv2>
  );
}
