import { Dropdown, Modal, Tabs, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { createContext, useCallback, useContext, useState } from "react";
import { classNames } from "react-extras";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  CampaignState,
  ICampaign,
  ICampaignItem,
  ICampaignStatsByUUID,
  IShow,
  User,
} from "redcircle-types";
import { Button, Table } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import { showModal } from "src/actions/modal";
import {
  endNowCampaignItem,
  overrideCampaignItems,
  removeCampaignItemAudio,
  removeCampaignItemResponse,
} from "src/action_managers/campaigns";
import { AlbumArt } from "src/components/lib/album_art";
import ContextMenu from "src/components/lib/context_menu/context_menu";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import { svgIcon as Icon } from "src/components/lib/icon";
import MediaPlayer from "src/components/lib/media_player";
import RedDot from "src/components/lib/red_dot/red_dot";
import {
  CAMPAIGN_ITEM_CANCEL_MODAL,
  CAMPAIGN_ITEM_PAUSE_MODAL,
  CAMPAIGN_ITEM_UN_CANCEL_MODAL,
  CAMPAIGN_ITEM_UNPAUSE_MODAL,
  PIXEL_MODAL,
} from "src/components/modals/modal_root";
import { sortCampaignItemsByDueDate } from "src/components/pages/ad_platform/show_advertising_page/show_advertising_page_util";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignItemStateToFriendly,
  CampaignItemStateToFriendlyPodcaster,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { TAppendedCampaignItem } from "src/hooks/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { ReactComponent as DownChevron } from "src/icons/down-chevron.svg";
import { getAverageCPM, isAudioSwapRequested } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { localDate, localDateAndTime, localTime } from "src/lib/date";
import { formatMoney } from "src/lib/format-money";
import { createOverrideResetRequest, getCampaignItemOverrides } from "src/lib/overrides";
import { canAdvertiserAccess } from "src/lib/permissions";
import { isInternalRCUser } from "src/lib/user";
import { RemovePodcastFromCart } from "../../../modals/remove_podcast_from_cart";
import OverridePopover from "../override_popover";
import { IAppendedCampaignItem } from "./campaign_page";
import { AudioSwapModalTableState } from "./campaign_page_dashboard";
import {
  formatStartAndEndDates,
  getCampaignItemContextText,
  getDaysLeftAndClockTimeString,
} from "./campaign_page_utils";
import CampaignProgress, { getCampaignProgress } from "./campaign_progress";

interface IProps {
  campaignItemsByState: Record<ICampaignItem["state"], ICampaignItem[]>;
  campaign?: ICampaign;
  campaignStats?: ICampaignStatsByUUID[string];
  audioSwapModalState: AudioSwapModalTableState;
  setAudioSwapModalState: React.Dispatch<React.SetStateAction<AudioSwapModalTableState>>;
  onModifyLineItems: (campaignItemUUIDs: string[]) => void;
  tabBarExtraContent?: any;
}

const CampaignContext = createContext({} as ICampaign);
const DAYS_BOLD_CUTOFF = 2;
const VALID_MODIFY_STATES = [
  CampaignItemStateDraft,
  CampaignItemStateSent,
  CampaignItemStateAccepted,
  CampaignItemStateRunning,
  CampaignItemStatePaused,
  CampaignItemStateNeedsScript,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateAudioSwapRequested,
];

export default function CampaignDashboardTable({
  campaignItemsByState,
  campaign,
  campaignStats,
  audioSwapModalState,
  setAudioSwapModalState,
  onModifyLineItems,
  tabBarExtraContent,
}: IProps) {
  const { user } = useSelectorTS((state) => state?.user);
  const dispatch = useDispatchTS();
  const [expandedRowKeys, setExpandedRowKeys] = useState<{ [key: string]: boolean }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [itemUUIDToRemove, setItemUUIDToRemove] = useState<string | undefined>(undefined);
  const [modal, contextHolder] = Modal.useModal();

  // all states besides draft
  const {
    sent,
    accepted,
    running,
    paused,
    completed,
    expired,
    declined,
    canceled,
    [CampaignItemStateNeedsScript]: needsScript,
    [CampaignItemStateAwaitingAudio]: awaitingAudio,
    [CampaignItemStateAudioSwapRequested]: audioSwapRequested,
  } = campaignItemsByState;

  // we have a default sort order for all items
  const allItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),
    ...running,
    ...accepted,
    ...paused,
    ...completed,
    ...expired,
    ...declined,
    ...canceled,
  ];
  const acceptedItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...awaitingAudio,
    ...running,
    ...accepted,
    ...paused,
    ...completed,
  ];
  const pendingActionItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),
    ...paused,
  ];
  const runningItems = [...running];

  // we track expanded rows through react state and not antd
  const handleRowExpandClick = (campaignUUID: string) => {
    const rows = { ...expandedRowKeys };
    if (rows[campaignUUID]) {
      delete rows[campaignUUID];
    } else {
      rows[campaignUUID] = true;
    }
    setExpandedRowKeys(rows);
  };

  const handleRenderMenu = useCallback(() => {
    const menu = {
      items: [
        {
          key: "modify",
          label: "Modify Line Items",
          hidden:
            !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
            !campaign?.isV2 ||
            !isInternalRCUser(user) ||
            !selectedRowKeys.some((key) => {
              const campaignItem = allItems.find((i) => i.uuid === key);
              return campaignItem && VALID_MODIFY_STATES.includes(campaignItem.state);
            }),
          onClick: () => {
            if (onModifyLineItems) onModifyLineItems(selectedRowKeys);
          },
        },
        {
          key: "removeModifications",
          label: "Remove Modifications",
          hidden:
            !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
            !campaign?.isV2 ||
            !isInternalRCUser(user) ||
            !selectedRowKeys.some((key) => {
              const campaignItem = allItems.find((i) => i.uuid === key);
              return (
                campaignItem &&
                getCampaignItemOverrides(campaignItem) &&
                VALID_MODIFY_STATES.includes(campaignItem.state)
              );
            }),
          onClick: () => {
            if (selectedRowKeys.length > 0) {
              const campaignItems = selectedRowKeys
                .map((uuid) => allItems.find((item) => item.uuid === uuid))
                .filter(Boolean) as ICampaignItem[];
              handleResetModify(campaignItems);
            }
          },
        },
        {
          key: "startNow",
          label: "Start Now",
          hidden:
            !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
            !campaign?.isV2 ||
            !isInternalRCUser(user) ||
            !selectedRowKeys.some((key) => {
              const campaignItem = allItems.find((i) => i.uuid === key);
              return (
                campaignItem &&
                getCampaignItemField("startAt", { campaignItem }) >= dayjs().unix() &&
                campaignItem.state === CampaignItemStateRunning
              );
            }),
          onClick: () => {
            if (selectedRowKeys.length > 0) {
              const validCampaignItems = selectedRowKeys
                .map((uuid) => allItems.find((item) => item.uuid === uuid))
                .filter((item) => {
                  return (
                    item &&
                    getCampaignItemField("startAt", { campaignItem: item }) >= dayjs().unix()
                  );
                }) as ICampaignItem[];

              modal.confirm({
                title: "Start Now",
                content: (
                  <>
                    You're about to start this campaign on{" "}
                    <strong>
                      {validCampaignItems.length} podcast{validCampaignItems.length > 1 ? "s" : ""}
                    </strong>{" "}
                    immediately. They were originally scheduled to start at{" "}
                    <strong>{localDateAndTime(validCampaignItems[0].startAt, true)}</strong>. Are
                    you sure?
                  </>
                ),
                okText: "Start Now",
                onOk: () => {
                  handleStartNow(validCampaignItems);
                },
              });
            }
          },
        },
      ].filter((i) => !i.hidden),
    };
    if (menu.items.length === 0) return null;
    return (
      <>
        {contextHolder}
        <Dropdown menu={menu}>
          <Button size="small" type="primary" className="m-la">
            Actions <AiFillCaretDown />
          </Button>
        </Dropdown>
      </>
    );
  }, [selectedRowKeys, allItems]);

  const handleResetModify = async (campaignItems: ICampaignItem[]) => {
    const request = campaignItems.reduce(
      (acc, campaignItem) => {
        const overrideRequest = createOverrideResetRequest(campaignItem);
        if (overrideRequest && Object.keys(overrideRequest).length > 0) {
          acc[campaignItem.uuid] = overrideRequest;
        }
        return acc;
      },
      {} as Record<string, any>
    );

    if (request && Object.keys(request).length > 0 && campaign) {
      const res = await dispatch(overrideCampaignItems({ campaignUUID: campaign.uuid, request }));
      if (res.status === 200) {
        dispatch(showSuccess("Modifications have been reset."));
      } else if ((res.json as any).validationErrors) {
        await dispatch(showError((res.json as any).validationErrors[0]?.errorMessage));
      }
      return res;
    }
  };

  const handleStartNow = async (campaignItems: ICampaignItem[]) => {
    const request = campaignItems.reduce(
      (acc, campaignItem) => {
        if (campaignItem.state !== CampaignItemStateRunning) return acc;
        if (getCampaignItemField("startAt", { campaignItem }) <= dayjs().unix()) return acc;
        if (!campaignItem.mediaFileUUID) return acc;
        // start the campaign in 1 minute - this is to allow the BE to process the request
        const newStartDate = dayjs().add(1, "minute").unix();
        acc[campaignItem.uuid] = { startAt: newStartDate };

        // adjust deadlines to be relative to the new start date, if invalid
        if (campaignItem?.uploadAudioBy && campaignItem.uploadAudioBy > newStartDate) {
          acc[campaignItem.uuid].assignAudioDeadline = newStartDate - 1;
        }
        if (campaignItem?.respondByAt && campaignItem.respondByAt > newStartDate) {
          acc[campaignItem.uuid].responseDeadline = newStartDate - 2;
        }
        return acc;
      },
      {} as Record<string, any>
    );

    if (request && Object.keys(request).length > 0 && campaign) {
      const res = await dispatch(overrideCampaignItems({ campaignUUID: campaign.uuid, request }));
      if (res.status === 200) {
        dispatch(
          showSuccess(
            campaignItems.length > 1
              ? `${campaignItems.length} Campaigns have been started.`
              : "Campaign has been started."
          )
        );
      } else if ((res.json as any).validationErrors) {
        await dispatch(showError((res.json as any).validationErrors[0]?.errorMessage));
      }
      return res;
    }
  };

  const showPixelModal = (campaignItem: ICampaignItem) =>
    dispatch(showModal(PIXEL_MODAL, { campaignItem }));

  const columns = [
    {
      title: "Podcast",
      key: "podcast",
      render: (campaignItem: ICampaignItem) => {
        const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
        const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

        const showAudioSwapMessage =
          !isPodcasterViewing && isAudioSwapActive && !isPodcasterInitiated;

        return (
          <TablePodcastCell
            rowIsExpanded={!!expandedRowKeys[campaignItem.uuid]}
            campaignItem={campaignItem}
            onClickExpand={handleRowExpandClick}
            showAudioSwapMessage={showAudioSwapMessage}
          />
        );
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        return a.show?.title?.localeCompare(b.show?.title ?? "") ?? 0;
      },
    },
    {
      title: "Progress",
      key: "progress",
      responsive: ["sm"],
      render: (campaignItem: IAppendedCampaignItem) => {
        return (
          <CampaignProgress
            campaignItem={campaignItem}
            campaignStats={campaignStats}
            campaign={campaignItem.campaign}
          />
        );
      },
    },
    {
      title: "Status",
      key: "status",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => (
        <TableStatusCell campaignItem={campaignItem} user={user} isPodcaster={false} />
      ),
      sorter: (a: ICampaignItem, b: ICampaignItem) => a.state.localeCompare(b.state),
    },
    {
      title: "Overrides",
      key: "overrides",
      width: 50,
      hidden: !allItems.some((item) => getCampaignItemOverrides(item)),
      render: (campaignItem: ICampaignItem) => {
        const overrides = getCampaignItemOverrides(campaignItem);
        if (overrides) return <OverridePopover overrides={overrides} />;
        return null;
      },
      sorter: (a: (typeof allItems)[0], b: (typeof allItems)[0]) => {
        const overridesA = getCampaignItemOverrides(a);
        const overridesB = getCampaignItemOverrides(b);
        if (!overridesA && !overridesB) return 0;
        if (!overridesA) return -1;
        if (!overridesB) return 1;
        return 0;
      },
    },
    {
      title: "Ad Audio",
      key: "context",
      render: (campaignItem: ICampaignItem) => {
        const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
        const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

        const showCancelAudioSwap =
          !isPodcasterViewing && isAudioSwapActive && !isPodcasterInitiated;

        return (
          <TableContextCell
            campaign={campaign}
            campaignItem={campaignItem}
            showPixelModal={showPixelModal}
            setAudioSwapModalState={setAudioSwapModalState}
            showCancelAudioSwap={showCancelAudioSwap}
            setItemUUIDToRemove={setItemUUIDToRemove}
            onClickModify={(campaignItem: ICampaignItem) => onModifyLineItems([campaignItem.uuid])}
            onClickResetModify={(campaignItem: ICampaignItem) => handleResetModify([campaignItem])}
            onClickStartNow={(campaignItem: ICampaignItem) => handleStartNow([campaignItem])}
          />
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys),
  };

  const sharedTableProps = {
    columns,
    rowKey: (campaignItem: ICampaignItem) => campaignItem.uuid,
    pagination: false,
    rowSelection,
    renderMenu: handleRenderMenu,
    expandable: {
      expandIcon: () => false,
      expandIconColumnIndex: -1,
      expandedRowRender: (campaignItem: ICampaignItem & { campaign: ICampaign; show: IShow }) => (
        <TableExpandedRow campaignItem={campaignItem} campaign={campaign} />
      ),
      expandedRowKeys: Object.keys(expandedRowKeys),
    },
  };

  return (
    <CampaignContext.Provider value={campaign || ({} as ICampaign)}>
      <Tabs
        defaultActiveKey={"all"}
        className="campaign-dashboard-table"
        onChange={() => {
          setExpandedRowKeys({});
          setSelectedRowKeys([]);
        }}
        tabBarExtraContent={tabBarExtraContent}>
        <Tabs.TabPane tab={`All (${allItems.length})`} key="all">
          <TableWithEmptyState
            dataSource={allItems}
            emptyState="There are currently no podcasts in this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Accepted (${acceptedItems.length})`} key="accepted">
          <TableWithEmptyState
            dataSource={acceptedItems}
            emptyState="There are currently no podcasts that have accepted this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <div className="flex-row-container align-center">
              Pending Action ({pendingActionItems.length})
              {pendingActionItems.length > 0 && <RedDot size={8} className="m-lxxxs m-bxxs" />}
            </div>
          }
          key="pending">
          <TableWithEmptyState
            dataSource={pendingActionItems}
            emptyState="There are currently no podcasts with pending invites for this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Running (${runningItems.length})`} key="running">
          <TableWithEmptyState
            dataSource={runningItems}
            emptyState="There are currently no podcasts actively running in this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
      </Tabs>

      <RemovePodcastFromCart
        itemUUIDToRemove={itemUUIDToRemove}
        setItemUUIDToRemove={setItemUUIDToRemove}
      />
    </CampaignContext.Provider>
  );
}

export const TableWithEmptyState = ({
  dataSource,
  emptyState = "No Data",
  rowSelection,
  renderMenu,
  ...props
}: any) => {
  if (!dataSource || dataSource.length === 0) {
    return (
      <div className="table-empty-state">
        <span className="text-subtle m-ts m-bs m-rs m-ls">{emptyState}</span>
      </div>
    );
  }

  return (
    <>
      {rowSelection?.selectedRowKeys?.length > 0 && (
        <Table.SelectedBar className="m-bxxs">
          <strong>{rowSelection?.selectedRowKeys?.length} selected</strong>
          {renderMenu && renderMenu()}
        </Table.SelectedBar>
      )}
      <Table dataSource={dataSource} rowSelection={rowSelection} {...props} />
    </>
  );
};

export const TablePodcastCell = ({
  campaignItem,
  rowIsExpanded,
  onClickExpand,
  showAudioSwapMessage,
}: {
  campaignItem: any;
  rowIsExpanded: boolean;
  onClickExpand: (campaignUUID: string) => void;
  showAudioSwapMessage: boolean;
}) => {
  const campaign = useContext(CampaignContext);
  const show = campaignItem.show;
  if (!show) return null;

  const isDisabled =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined ||
    campaignItem.state === CampaignItemStatePaused;

  const handleClickExpand = () => {
    onClickExpand(campaignItem.uuid);
  };

  const total = formatMoney(campaignItem.totalBudget);
  const cpm = formatMoney(getAverageCPM({ show, campaign, campaignItem }));

  return (
    <div>
      {showAudioSwapMessage && (
        <span className="flex-row-container align-center m-bxxxs fs-11 lh-xs">
          Replacement audio request pending{" "}
          <Tooltip title="Campaign paused on show until audio is swapped or request is canceled">
            <Icon
              name="info"
              className="m-hxxs"
              style={{
                height: "13px",
                width: "13px",
                color: "#C6C6C6",
              }}
            />
          </Tooltip>
        </span>
      )}

      <div className={classNames("campaign-table-cell", isDisabled && "disabled")}>
        <Link to={`/browse/${show.uuid}`}>
          <AlbumArt
            src={show.imageURL}
            style={{ width: 48, marginRight: 12 }}
            imageSize={"64x64"}
          />
        </Link>
        <div className="flex-column-container">
          <strong className="line-clamp-1 title" title={show.title}>
            {show.title}
          </strong>

          <div className="flex-row-container align-center">
            <span className="m-rxxs m-txxxs fs-13">
              Total: <strong>{total}</strong> | {"CPM"}: <strong>{cpm}</strong>
            </span>
            {campaignItem?.offerRates?.enabled && (
              <Tooltip title="This is the updated rate you offered this show.">
                <Icon name="money-tag" className="money-tag m-rxxs" />
              </Tooltip>
            )}
            <button
              type="button"
              className={classNames("svg-button", rowIsExpanded && "expanded")}
              onClick={handleClickExpand}>
              <DownChevron />
            </button>
          </div>

          <span className="d-block d-none-sm">
            <ExclusiveTag className={`no-wrap tag-${campaignItem.state} podcaster`}>
              {
                CampaignItemStateToFriendlyPodcaster[
                  campaignItem.state as keyof typeof CampaignItemStateToFriendlyPodcaster
                ]
              }
            </ExclusiveTag>
          </span>
        </div>
      </div>
    </div>
  );
};

export const TableStatusCell = ({
  campaignItem,
  user,
  isPodcaster,
  className,
}: {
  campaignItem: TAppendedCampaignItem;
  user: User;
  isPodcaster: boolean;
  className?: string;
}) => {
  const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

  const today = Math.floor(Date.now() / 1000);
  const audioSwapDate = Number(campaignItem?.swapAudioInfo?.requestedAt);
  const fiveDay = 60 * 60 * 24 * 5;
  const showNewAudioText = today <= audioSwapDate + fiveDay;

  const showNewAudioMessage =
    !isPodcasterViewing && isAudioSwapActive && showNewAudioText && isPodcasterInitiated;

  const { percent } = getCampaignProgress(campaignItem);
  const inProgressButZero = percent === 0 && campaignItem.state === CampaignItemStateRunning;

  const now = dayjs();
  const endAt = dayjs.unix(campaignItem.lifecycleSettings.endAt.value);

  let campaignItemStateUsed = campaignItem.state;

  if (inProgressButZero) {
    // The campaignItem state can show in progress but nothing has been inserted yet, showing
    // the "not started" state on this condition for more intuitive UI experience.
    campaignItemStateUsed = CampaignItemStateAccepted;
  } else if (campaignItem.hardEndDate.value && endAt.isValid() && endAt.isBefore(now)) {
    /**
     * Incase the campaign item has been ended early
     */
    campaignItemStateUsed = CampaignItemStateCompleted;
  }

  return (
    <span className={className}>
      {showNewAudioMessage && (
        <p className="color-primary d-inline-block m-rxxs m-t0">New Audio!</p>
      )}
      <ExclusiveTag
        className={`no-wrap tag-${campaignItemStateUsed} ${isPodcaster ? "podcaster" : ""}`}>
        {isPodcaster
          ? CampaignItemStateToFriendlyPodcaster[campaignItemStateUsed]
          : CampaignItemStateToFriendly[campaignItemStateUsed]}
      </ExclusiveTag>
    </span>
  );
};

export const TableContextCell = ({
  campaign,
  campaignItem,
  showPixelModal,
  setAudioSwapModalState,
  showCancelAudioSwap,
  setItemUUIDToRemove,
  onClickModify,
  onClickResetModify,
  onClickStartNow,
}: {
  campaign?: ICampaign;
  campaignItem: ICampaignItem;
  showPixelModal: any;
  setAudioSwapModalState: any;
  showCancelAudioSwap: boolean;
  setItemUUIDToRemove: any;
  onClickModify: (campaignItem: ICampaignItem) => void;
  onClickResetModify: (campaignItem: ICampaignItem) => void;
  onClickStartNow: (campaignItem: ICampaignItem) => void;
}) => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const [modal, contextHolder] = Modal.useModal();

  const showMap = useSelectorTS((state) => state.publicShows);
  const showTitle = showMap[campaignItem?.showUUID]?.title;

  const allMenuItems: {
    label: string;
    onClick: () => void | any;
    hidden?: boolean;
    validStates?: ICampaignItem["state"][];
    invalidStates?: ICampaignItem["state"][];
    isValid?: (campaignItem: ICampaignItem) => boolean;
    auth?: boolean;
  }[] = [
    {
      label: "Modify Line Item",
      onClick: () => onClickModify(campaignItem),
      hidden:
        !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
        !campaignItem.isV2 ||
        !isInternalRCUser(user),
      validStates: VALID_MODIFY_STATES,
    },
    {
      label: "Remove Modifications",
      onClick: () => onClickResetModify(campaignItem),
      hidden:
        !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
        !campaignItem.isV2 ||
        !isInternalRCUser(user) ||
        !getCampaignItemOverrides(campaignItem),
      validStates: VALID_MODIFY_STATES,
    },
    {
      label: "Reactivate",
      onClick: () => onClickModify(campaignItem),

      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        ) {
          return false;
        }

        const { responseTask, completed } = item.lifecycleSettings;

        const now = dayjs();
        const endAt = dayjs.unix(item.lifecycleSettings.endAt.value);
        const hasHardEndDate = item.hardEndDate.value;
        const responseTaskDeadline = dayjs.unix(responseTask?.deadline?.value);

        const declined = responseTask.completed && !item.response?.accepted;

        const expired = !responseTask.completed || now.isAfter(responseTaskDeadline);

        const completedViaHardEndDate = hasHardEndDate && now.isAfter(endAt);

        return declined || expired || completed || completedViaHardEndDate;
      },
    },
    {
      label: "Start Now",
      onClick: () => {
        const originalStartDate = getCampaignItemField("startAt", { campaignItem });
        modal.confirm({
          title: "Start Now",
          content: (
            <>
              You're about to start this campaign on <strong>{campaignItem.show?.title}</strong>{" "}
              immediately. It was originally scheduled to start at{" "}
              <strong>{localDateAndTime(originalStartDate, true)}</strong>. Are you sure?
            </>
          ),
          okText: "Start Now",
          onOk: () => {
            onClickStartNow(campaignItem);
          },
        });
      },
      hidden:
        !campaignItem.isV2 ||
        // use a minute buffer if the campaign is gonna start soon anyways
        getCampaignItemField("startAt", { campaignItem }) <= dayjs().add(1, "minute").unix(),
      validStates: [CampaignItemStateRunning],
    },
    {
      label: "Configure Pixel URL",
      onClick: () => showPixelModal(campaignItem),
      hidden: !canAdvertiserAccess(permissionTypes.editCampaign, campaign),
      invalidStates: [
        CampaignItemStateDraft,
        CampaignItemStateCompleted,
        CampaignItemStateDeclined,
        CampaignItemStateExpired,
      ],
    },
    {
      label: "Report Audio Issue",
      onClick: () => {
        setAudioSwapModalState((prev: any) => ({
          ...prev,
          currentCampaignItemUUID: campaignItem?.uuid,
          audioSwapModal: true,
        }));
      },
      hidden: !canAdvertiserAccess(permissionTypes.editCampaign, campaign) || showCancelAudioSwap,
      isValid: (item) => {
        if (!canAdvertiserAccess(permissionTypes.editCampaign, campaign) || showCancelAudioSwap) {
          return false;
        }
        const now = dayjs();
        const endAt = dayjs.unix(item?.lifecycleSettings?.endAt?.value);
        const hasHardEndDate = item?.hardEndDate?.value;

        const isCompletedViaHardEndDate = item.isV2 && hasHardEndDate && now.isAfter(endAt);

        /**
         * Going to Keep V1 logic for now
         * TODO migrate all context menu options display logic to V2
         */
        return (
          [
            CampaignItemStateRunning,
            CampaignItemStateAccepted,
            CampaignItemStateAudioSwapRequested,
          ].includes(item.state) && !isCompletedViaHardEndDate
        );
      },
    },
    {
      label: "Cancel Ad Audio Swap",
      onClick: () => {
        setAudioSwapModalState((prev: any) => ({
          ...prev,
          currentCampaignItemUUID: campaignItem?.uuid,
          cancelAudioSwapModal: true,
        }));
      },
      hidden: !canAdvertiserAccess(permissionTypes.editCampaign, campaign) || !showCancelAudioSwap,
      validStates: [
        CampaignItemStateRunning,
        CampaignItemStateAccepted,
        CampaignItemStateAudioSwapRequested,
      ],
    },
    {
      label: "Pause Ad",
      onClick: () => {
        dispatch(
          showModal(CAMPAIGN_ITEM_PAUSE_MODAL, {
            showUUID: campaignItem.showUUID,
            campaignItemUUID: campaignItem.uuid,
          })
        );
      },
      isValid: (item) => {
        if (!item.isV2 || !canAdvertiserAccess(permissionTypes.editCampaign, campaign)) {
          return false;
        }

        const now = dayjs();
        const endAt = dayjs.unix(item.lifecycleSettings.endAt.value);
        const hasHardEndDate = item.hardEndDate.value;

        const {
          sendTask,
          responseTask,
          assignAudioTask,
          assignPixelTask,
          assignScriptTask,
          pixelRequired,
          swapAudioPending,
          completed,
          paused,
          canceled,
        } = item.lifecycleSettings;

        const accepted = !!item.response?.accepted;

        const isReady =
          sendTask.completed &&
          responseTask.completed &&
          accepted &&
          assignAudioTask.completed &&
          assignScriptTask.completed &&
          (pixelRequired.value ? assignPixelTask.completed : true) &&
          !swapAudioPending;

        return (
          isReady &&
          !paused &&
          !completed &&
          !canceled &&
          (!hasHardEndDate || (hasHardEndDate && now.isBefore(endAt)))
        );
      },
    },
    {
      label: "Unpause",
      onClick: () => {
        dispatch(
          showModal(CAMPAIGN_ITEM_UNPAUSE_MODAL, {
            showUUID: campaignItem.showUUID,
            campaignItemUUID: campaignItem.uuid,
          })
        );
      },
      isValid: (item) => {
        if (!item.isV2 || !canAdvertiserAccess(permissionTypes.editCampaign, campaign)) {
          return false;
        }

        const { paused, pixelRequired, assignPixelTask } = item.lifecycleSettings;

        const startAt = dayjs.unix(item.lifecycleSettings.startAt.value);
        const now = dayjs();

        /**
         * Paused state indirectly triggered from pixel requirement, cannot unpause it from endpoint so unpause should not display in this state
         */
        const isAutoPaused = pixelRequired.value
          ? !assignPixelTask.completed && now.isAfter(startAt)
          : false;

        return paused && !isAutoPaused;
      },
    },
    {
      label: "Remove Podcast",
      onClick: () => setItemUUIDToRemove(campaignItem?.uuid),
      auth: true,
      validStates: [CampaignItemStateDeclined, CampaignItemStateExpired],
    },
    {
      label: "Remove Response",
      onClick: () => {
        modal.confirm({
          title:
            "This action will reset the line item as if the podcaster did not respond to the deal. Are you sure?",

          okText: "Remove",
          onOk: async () => {
            await dispatch(
              removeCampaignItemResponse(
                { campaignItemUUID: campaignItem.uuid },
                { successMessage: `Successfully removed podcaster response.` }
              )
            );
          },
        });
      },
      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        ) {
          return false;
        }

        const { responseTask, assignAudioTask, completed, sendTask, paused, canceled } =
          item.lifecycleSettings;
        const startAt = dayjs.unix(item?.lifecycleSettings?.startAt?.value);
        const now = dayjs();

        return (
          sendTask.completed &&
          responseTask.completed &&
          !assignAudioTask.completed &&
          now.isBefore(startAt) &&
          !completed &&
          !paused &&
          !canceled
        );
      },
    },
    {
      label: "Remove Audio",
      onClick: () => {
        modal.confirm({
          title:
            "This action will remove the podcaster's audio upload as if the podcaster never uploaded their ad read. Are you sure?",

          okText: "Remove",
          onOk: async () => {
            await dispatch(
              removeCampaignItemAudio(
                { campaignItemUUID: campaignItem.uuid },
                { successMessage: `Successfully removed podcaster audio.` }
              )
            );
          },
        });
      },
      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        ) {
          return false;
        }

        const { responseTask, assignAudioTask, completed, sendTask, paused, canceled } =
          item.lifecycleSettings;

        const startAt = dayjs.unix(item?.lifecycleSettings?.startAt?.value);
        const now = dayjs();

        return (
          sendTask.completed &&
          responseTask.completed &&
          assignAudioTask.completed &&
          now.isBefore(startAt) &&
          !paused &&
          !canceled &&
          !completed
        );
      },
    },
    {
      label: "Cancel",
      onClick: () => {
        dispatch(
          showModal(CAMPAIGN_ITEM_CANCEL_MODAL, {
            campaignItem: campaignItem,
          })
        );
      },
      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        ) {
          return false;
        }

        const { canceled, completed, paused } = item.lifecycleSettings;
        const startAt = dayjs.unix(item?.lifecycleSettings?.startAt?.value);
        const now = dayjs();

        return now.isBefore(startAt) && !canceled && !completed && !paused;
      },
    },
    {
      label: "Restore",
      onClick: () => {
        dispatch(
          showModal(CAMPAIGN_ITEM_UN_CANCEL_MODAL, {
            campaignItem: campaignItem,
            campaign: campaign,
          })
        );
      },
      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        )
          return false;

        const campaignIsComplete = campaign?.state === CampaignState.CampaignStateCompleted;

        return item?.lifecycleSettings?.canceled && !campaignIsComplete;
      },
    },
    {
      label: "End Now",
      onClick: () => {
        modal.confirm({
          title:
            "This action will change the line item's end date to the current date and time. This will end ad insertion for this line item.  Are you sure?",
          okText: "End Now",
          onOk: async () => {
            await dispatch(
              endNowCampaignItem(
                { campaignItemUUID: campaignItem.uuid },
                {
                  successMessage: `Successfully ended the line item for ${showTitle}`,
                }
              )
            );
          },
        });
      },
      isValid: (item) => {
        if (
          !canAdvertiserAccess(permissionTypes.editCampaign, campaign) ||
          !item.isV2 ||
          !isInternalRCUser(user)
        ) {
          return false;
        }

        const {
          completed,
          canceled,
          paused,
          responseTask,
          assignAudioTask,
          sendTask,
          assignScriptTask,
          assignPixelTask,
          pixelRequired,
          swapAudioPending,
        } = item.lifecycleSettings;

        const startAt = dayjs.unix(item?.lifecycleSettings?.startAt?.value);
        const endAt = dayjs.unix(item.lifecycleSettings.endAt.value);
        const hasHardEndDate = item.hardEndDate.value;
        const now = dayjs();

        const isReady =
          sendTask.completed &&
          responseTask.completed &&
          assignAudioTask.completed &&
          assignScriptTask.completed &&
          (pixelRequired.value ? assignPixelTask.completed : true) &&
          !swapAudioPending;

        return (
          isReady &&
          now.isAfter(startAt) &&
          !completed &&
          !canceled &&
          !paused &&
          (!hasHardEndDate || (hasHardEndDate && now.isBefore(endAt)))
        );
      },
    },
  ];

  const menuItems = allMenuItems.reduce(
    (acc, item) => {
      const { label, onClick, validStates, invalidStates, hidden, isValid } = item;
      if (hidden) return acc;
      if (validStates && !validStates.includes(campaignItem.state)) return acc;
      if (invalidStates && invalidStates.includes(campaignItem.state)) return acc;
      if (typeof isValid === "function" && !isValid?.(campaignItem)) return acc;

      acc[label] = onClick;
      return acc;
    },
    {} as Record<string, () => void>
  );
  const showContextMenu = Object.keys(menuItems).length > 0;

  const showMediaPlayer = !!campaignItem.mediaFileUUID;
  const showText =
    campaignItem.state === CampaignItemStateSent ||
    campaignItem.state === CampaignItemStateAwaitingAudio;
  const showEndDate =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined;
  const showCTA = campaignItem.state === CampaignItemStateNeedsScript;

  return (
    <div className="campaign-table-cell context">
      {showMediaPlayer && <MediaPlayer mediaFileUUID={campaignItem.mediaFileUUID} mini />}
      {campaignItem.state === CampaignItemStateNeedsScript && (
        <TableDueDate timestamp={campaignItem?.scriptDueBy} />
      )}
      {showText && <span className="fs-13">{getCampaignItemContextText(campaignItem)}</span>}
      {showEndDate && (
        <span className="fs-13 end-date">{getCampaignItemContextText(campaignItem)}</span>
      )}
      {showCTA && <TableCTA campaignItem={campaignItem} />}
      {showContextMenu && <ContextMenu menuItems={menuItems} noCircle={true} />}
      {contextHolder}
    </div>
  );
};

export const TableExpandedRow = ({
  campaignItem,
  campaign,
}: {
  campaignItem: TAppendedCampaignItem;
  campaign?: ICampaign;
}) => {
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);
  const pacing = getCampaignItemField("pacing", { campaignItem });
  const hardEndDate = getCampaignItemField("hardEndDate", { campaignItem });

  const today = new Date().getTime() / 1000;
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    hour12: true,
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const audioSwapDate = dateFormatter.format(
    Number(campaignItem?.swapAudioInfo?.requestedAt ?? 0) * 1000
  );
  const showNewAudioText =
    today <= Number(campaignItem?.swapAudioInfo?.requestedAt ?? 0) + 5 * 24 * 60 * 60;
  const showSwapAudioInfoBlock = isAudioSwapActive && showNewAudioText;

  const SwapAudioInfoBlock = (
    <>
      <p className="m-bxxs lh-s fs-13">
        {isPodcasterInitiated ? "New Audio live" : "Campaign Paused"} as of{" "}
        <strong>
          {audioSwapDate} - {dateFormatter.resolvedOptions().timeZone}
        </strong>
      </p>
      {campaignItem?.swapAudioInfo?.reason?.length > 0 && (
        <p className="m-bxxs lh-s fs-13">
          Reason for Replacement Audio ({isPodcasterInitiated ? "Podcaster" : "Brand"}):{" "}
          <strong>{campaignItem?.swapAudioInfo?.reason}</strong>
        </p>
      )}
    </>
  );

  const showDeclinedReason =
    campaignItem.state === CampaignItemStateDeclined &&
    typeof campaignItem.declinedReason === "string" &&
    campaignItem.declinedReason.length > 0;

  const declinedReasonBlock = (
    <p className="m-bxxs lh-s fs-13">
      Reason for decline: <strong>{campaignItem?.declinedReason}</strong>
    </p>
  );

  const { canceled, canceledReason } = campaignItem?.lifecycleSettings ?? {};

  const showCanceledReason =
    canceled && typeof canceledReason === "string" && canceledReason?.length > 1;

  const canceledReasonBlock = (
    <p className="m-bxxs lh-s fs-13">
      Reason for cancel: <strong>{canceledReason}</strong>
    </p>
  );

  return (
    <div className="p-vxxs m-lxl fs-13">
      <p>
        Podcaster Response Due: <strong>{localDateAndTime(campaignItem.respondByAt, true)}</strong>
      </p>
      <p>
        Podcaster Audio Due: <strong>{localDateAndTime(campaignItem.uploadAudioBy, true)}</strong>
      </p>
      <p>
        {!pacing || (pacing && !hardEndDate) ? "Estimated" : ""} Timeline:{" "}
        <strong>
          {formatStartAndEndDates(campaignItem, campaignItem.campaign, campaignItem.show, "LL LT")}
        </strong>
      </p>
      {showSwapAudioInfoBlock && SwapAudioInfoBlock}
      {showDeclinedReason && declinedReasonBlock}
      {showCanceledReason && canceledReasonBlock}
    </div>
  );
};

export const TableDueDate = ({ timestamp }: { timestamp: number | undefined }) => {
  if (!timestamp) return null;
  const { daysLeft } = getDaysLeftAndClockTimeString(timestamp);
  const date = localDate(timestamp);
  const time = localTime(timestamp);
  if (daysLeft < DAYS_BOLD_CUTOFF) {
    return (
      <div className="due-date flex-column-container fs-13 d-none d-flex-sm">
        <span>
          Due by <strong>{date}</strong>
        </span>
        <strong>{time}</strong>
      </div>
    );
  }
  return (
    <div className="due-date flex-column-container fs-13 d-none d-flex-sm">
      <span>Due by {date}</span>
      <span>{time}</span>
    </div>
  );
};

const TableCTA = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const path = `/campaigns/${campaignItem.campaignUUID}/script`;

  const renderLink = (copy: string, isPriority?: boolean) => (
    <Link to={path} className="cta">
      <Button type={isPriority ? "primary" : "secondary-red"}>{copy}</Button>
    </Link>
  );

  if (campaignItem.state === CampaignItemStateNeedsScript && campaignItem.scriptDueBy) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.scriptDueBy);
    return renderLink("Assign Script", daysLeft < DAYS_BOLD_CUTOFF);
  }
  return null;
};
