import { Col, Form, InputNumber, Row } from "antd";
import { AiFillCaretRight } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { ICampaign } from "redcircle-types";
import { Button, Divider } from "redcircle-ui";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";

const DEFAULT_CUT = 3000;

export default function ModifyPricingForm({
  campaign,
  campaignItem,
}: {
  campaign?: ICampaign;
  campaignItem?: ICampaignItem;
}) {
  const form = Form.useFormInstance();
  const cut = Form.useWatch("cut", form);
  const cpm = Form.useWatch("cpm", form);
  const totalBudget = Form.useWatch("totalBudget", { form, preserve: true });

  // get budget
  const budgets = useSelectorTS((state) => state.budgets);
  const budget = campaignItem?.budgetUUID ? budgets[campaignItem.budgetUUID] : undefined;

  // get stats
  const campaignStatsByUUID = useSelectorTS((state) => state.campaigns.campaignStatsByUUID);
  const campaignStats = campaignItem && campaignStatsByUUID?.[campaignItem.campaignUUID];

  // get show
  const publicShows = useSelectorTS((state) => state.publicShows);
  const show = campaignItem && publicShows[campaignItem.showUUID];

  // calculate fields
  const campaignItemCut = (campaignItem?.advertisingCutBasisPoints || DEFAULT_CUT) / 100;
  const campaignItemCPM = getAverageCPM({ campaign, campaignItem, show, when: "final" }) / 100;
  const campaignItemImp =
    (campaignItem && campaignStats?.downloadsByShowUUID?.[campaignItem.showUUID]) ?? 0;
  const campaignItemSpend = budget ? budget.current / 1000 / 100 : 0;

  const campaignItemTotalBudget = (campaignItem?.totalBudget ?? 0) / 100;
  const campaignItemRemainingBudget = campaignItemTotalBudget - campaignItemSpend;

  const campaignItemTotalImp = Math.floor(campaignItemTotalBudget / (campaignItemCPM / 1000));
  const campaignItemRemainingImp = campaignItemTotalImp - campaignItemImp;

  // we have to factor in the initial impressions after current spend
  const totalImpressions =
    campaignItemImp + Math.floor((totalBudget - campaignItemSpend) / (cpm / 1000));

  const isBudgetChanged = totalBudget !== campaignItemTotalBudget;
  const isCPMChanged = cpm !== campaignItemCPM;
  const isCutChanged = cut !== campaignItemCut;

  if (!campaignItem) return null;
  return (
    <div className="flex-column-container">
      <Row>
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">Total Budget</div>
          <div className="p-txxxs">
            <strong>{formatMoney(campaignItemTotalBudget)}</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified Total Budget</div>
          <div className="flex-row-container">
            <Form.Item name="totalBudget" className="m-b0">
              <InputNumber
                value={totalBudget}
                prefix="$"
                min={0}
                precision={0}
                style={{ width: 120 }}
              />
            </Form.Item>
            {isBudgetChanged && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => form.setFieldsValue({ totalBudget: campaignItemTotalBudget })}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">CPM</div>
          <div className="p-txxxs">
            <strong>{formatMoney(campaignItemCPM)}</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified CPM</div>
          <div className="flex-row-container">
            <Form.Item name="cpm" className="m-b0">
              <InputNumber prefix="$" min={0.01} precision={2} style={{ width: 120 }} />
            </Form.Item>
            {isCPMChanged && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => form.setFieldsValue({ cpm: campaignItemCPM })}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">RedCircle Cut</div>
          <div className="p-txxxs">
            <strong>{campaignItemCut}%</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified Cut</div>
          <div className="flex-row-container">
            <Form.Item name="cut" className="m-b0">
              <InputNumber suffix="%" min={0.01} style={{ width: 120 }} />
            </Form.Item>
            {isCutChanged && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => form.setFieldsValue({ cut: campaignItemCut })}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Divider className="m-yxs" />

      <h5>Line Item Details</h5>

      <Row>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label m-txs">Spend So Far</div>
          <strong>{formatMoney(campaignItemSpend)}</strong>
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label m-txs">Remaining Budget</div>
          <strong
            style={{
              textDecoration: isBudgetChanged ? "line-through" : "none",
            }}>
            {formatMoney(campaignItemRemainingBudget)}
          </strong>
          {isBudgetChanged && (
            <strong className="m-lxxs">{formatMoney(totalBudget - campaignItemSpend)}</strong>
          )}
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label m-txs">Total Budget</div>
          <strong
            style={{
              textDecoration: isBudgetChanged ? "line-through" : "none",
            }}>
            {formatMoney(campaignItemTotalBudget)}
          </strong>
          {isBudgetChanged && <strong className="m-lxxs">{formatMoney(totalBudget)}</strong>}
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Impressions So Far</div>
          <strong>{campaignItemImp ?? "N/A"}</strong>
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Remaining Impressions</div>
          <strong
            style={{
              textDecoration: isBudgetChanged || isCPMChanged ? "line-through" : "none",
            }}>
            {campaignItemRemainingImp}
          </strong>
          {(isBudgetChanged || isCPMChanged) && (
            <strong className="m-lxxs">{totalImpressions - campaignItemImp}</strong>
          )}
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Total Impressions</div>
          <strong
            style={{
              textDecoration: isBudgetChanged || isCPMChanged ? "line-through" : "none",
            }}>
            {campaignItemTotalImp}
          </strong>
          {(isBudgetChanged || isCPMChanged) && (
            <strong className="m-lxxs">{totalImpressions}</strong>
          )}
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Podcaster Cut</div>
          <strong
            style={{
              textDecoration: isCutChanged || isBudgetChanged ? "line-through" : "none",
            }}>
            {formatMoney((campaignItemTotalBudget * (100 - campaignItemCut)) / 100)}
          </strong>
          {(isCutChanged || isBudgetChanged) && (
            <strong className="m-lxxs">{formatMoney((totalBudget * (100 - cut)) / 100)}</strong>
          )}
        </Col>
      </Row>
    </div>
  );
}
