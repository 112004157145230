import dayjs, { Dayjs } from "dayjs";
import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { CampaignPaymentType, ICampaign, ICampaignItem, Script } from "redcircle-types";
import { getScriptsForCampaign } from "src/action_managers/script";
import {
  CampaignInvoicingMonthTypeCalendar,
  CampaignInvoicingStyleFull,
  CampaignInvoicingStyleMonthly,
  CampaignItemStateDraft,
  CreditCardPaymentMethod,
  InvoicingPaymentMethod,
} from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { useForecastEndDates, useForecastImpressions } from "src/hooks/streamulator";
import { getAverageCPM, getBudget, getDailyImpressions } from "src/lib/campaigns";
import { getAddScriptInitialState, ScriptForm } from "../add_script_modal/add_script_modal";
import {
  initializeCampaignDeadlines,
  initializeCampaignTimeline,
} from "./campaign_scheduler_utils";
import { calcInitialValues, LocalScriptAssignments } from "./campaign_script_utils";
import { isUUID } from "src/lib/uuid";

interface IState {
  campaignUUID?: string;
  campaign?: ICampaign;
  campaignItems: ICampaignItem[];
  excludedCampaignItems: ICampaignItem[];

  budgets: { [campaignItemUUID: string]: number };
  setBudgets: Dispatch<
    SetStateAction<{
      [campaignItemUUIDs: string]: number;
    }>
  >;
  timeline: Dayjs[];
  setTimeline: Dispatch<SetStateAction<dayjs.Dayjs[]>>;
  deadlines: Record<string, Dayjs | undefined | null>;
  setDeadlines: Dispatch<SetStateAction<Record<string, dayjs.Dayjs | null | undefined>>>;
  minEndDatesState: ReturnType<typeof useForecastEndDates>;
  maxImpressionsState: ReturnType<typeof useForecastImpressions>;
  isSchedulerLoading: boolean;

  scripts: Script[];
  scriptState: ScriptForm[];
  setScriptState: (value: ScriptForm[]) => void;
  scriptAssignmentState: LocalScriptAssignments;
  setScriptAssignmentState: (value: LocalScriptAssignments) => void;

  invoicingStyle: ICampaign["invoicingStyle"] | undefined;
  setInvoicingStyle: (value: ICampaign["invoicingStyle"] | undefined) => void;
  invoicingMonthType: ICampaign["invoicingMonthType"] | undefined;
  setInvoicingMonthType: (value: ICampaign["invoicingMonthType"] | undefined) => void;
  paymentMethodType: CampaignPaymentType | undefined;
  setPaymentMethodType: (value: CampaignPaymentType | undefined) => void;
  paymentCardId: string | undefined;
  setPaymentCardId: (value: string | undefined) => void;
}

/**
 * CampaignSchedulerContext holds state for the entire campaign scheduler flow
 * This includes budgets, timelines and forecasted data
 */
export const CampaignSchedulerContext = createContext({} as IState);

export const CampaignSchedulerContextWrapper = ({
  children,
  campaignUUID,
}: {
  children: React.ReactNode;
  campaignUUID?: string;
}) => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const [didReset, setDidReset] = useState(false);

  // ALL STATE =====================================================================================
  const { campaigns, isLoading: isCampaignsLoading } = useSelectorTS((state) => state.campaigns);
  const campaign = campaignUUID ? campaigns[campaignUUID] : undefined;
  const {
    campaignItemByCampaignUUID,
    campaignItems: allCampaignItems,
    isLoading: isCampaignItemsLoading,
  } = useSelectorTS((state) => state.campaignItems);
  const campaignItemUUIDs =
    campaignUUID && campaignItemByCampaignUUID ? campaignItemByCampaignUUID[campaignUUID] : [];
  const campaignItems = campaignItemUUIDs
    ? campaignItemUUIDs.map((uuid) => allCampaignItems[uuid])
    : [];
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const publicShows = useSelectorTS((state) => state.publicShows);

  const showToItemUUID = draftItems?.reduce(
    (accu, curr) => {
      accu[curr.showUUID] = curr.uuid;
      return accu;
    },
    {} as { [showUUID: string]: string }
  );

  // check for exclusions
  const excludedCampaignItems = campaign
    ? campaignItems.filter((c) => {
        const show = publicShows[c.showUUID];
        const excludedCategories = show?.advertisementSettings?.excludedCategories;
        const excludedBrandInstanceUUIDs = show?.advertisementSettings?.excludedBrandInstanceUUIDs;
        const brand = campaign.brand;
        if (!brand) return false;
        return (
          excludedCategories?.includes(brand.iabCategory) ||
          excludedBrandInstanceUUIDs?.includes(brand.instanceUUID)
        );
      })
    : [];

  // SCHEDULER STATE ===============================================================================
  const [budgets, setBudgets] = useState<{ [campaignItemUUIDs: string]: number }>({});
  const [timeline, setTimeline] = useState<Dayjs[]>([]);
  const [deadlines, setDeadlines] = useState<Record<string, Dayjs | undefined | null>>({
    audioDue: undefined,
    responseDue: undefined,
  });
  const startDate = timeline?.[0]?.unix();
  const endDate = timeline?.[1]?.unix();

  // pacing forecasts
  const minEndDatesState = useForecastEndDates({
    user,
    campaign,
    draftItems,
    publicShows,
    budgets,
    startDate,
  });

  const maxImpressionsState = useForecastImpressions({
    user,
    campaign,
    draftItems,
    publicShows,
    startDate,
    endDate,
  });

  /**
   * Initialize timeline on campaign starts at changes
   */
  useEffect(() => {
    if (!campaign) return;
    setTimeline(initializeCampaignTimeline(campaign));
  }, [campaign?.startsAt, campaign?.startsAtV2]);

  // update end dates from the reforecast when timeline changes for non-paced campaigns
  useEffect(() => {
    if (!campaign || campaign.pacing) return;
    const nonPacingLatestEndDate =
      Object.values(minEndDatesState.minEndDates)?.length > 0
        ? Object.values(minEndDatesState.minEndDates)?.reduce((accu: number, curr: any) => {
            if (!curr.streamulatorErrored) {
              accu = accu >= curr.estimatedEndTime ? accu : curr.estimatedEndTime;
            }
            return accu;
          }, startDate)
        : endDate;
    if (nonPacingLatestEndDate !== endDate) {
      setTimeline([timeline[0], dayjs.unix(nonPacingLatestEndDate)]);
    }
  }, [timeline, minEndDatesState]);

  // initialize deadlines
  useEffect(() => {
    if (!campaign) return;
    setDeadlines(initializeCampaignDeadlines(campaign));
  }, [campaign?.assignAudioDeadline, campaign?.responseDeadline]);

  /**
   * Initialize budgets for non paced campaigns, needed for end date forecaster initial end date
   */
  useEffect(() => {
    const isBudgetsEmpty = Object.keys(budgets).length === 0;
    const startDate = timeline?.[0]?.unix() ?? 0;
    const endDate = timeline?.[1]?.unix() ?? 0;
    const isPublicShowsLoaded =
      publicShows?.isLoading === false &&
      draftItems?.every(({ showUUID }) => publicShows?.[showUUID]?.uuid === showUUID);

    if (
      !!campaign &&
      Array.isArray(draftItems) &&
      draftItems.length > 0 &&
      !!publicShows &&
      isPublicShowsLoaded &&
      typeof startDate === "number" &&
      startDate !== 0 &&
      typeof endDate === "number" &&
      endDate !== 0 &&
      isBudgetsEmpty &&
      !campaign.pacing
    ) {
      const initialBudgets = draftItems.reduce(
        (acc, draftItem) => {
          const { uuid, showUUID, totalBudget } = draftItem;
          const publicShow = publicShows[showUUID];
          const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem: draftItem });
          const dailyImpressions = getDailyImpressions(publicShow);
          acc[uuid] =
            typeof totalBudget === "number" && totalBudget > 0
              ? totalBudget
              : getBudget({ cpm, dailyImpressions, startDate, endDate });
          return acc;
        },
        {} as { [campaignItemUUID: string]: number }
      );
      setBudgets(initialBudgets);
    }
  }, [campaign, draftItems, publicShows, timeline, budgets]);

  /**
   * Initialize budgets for paced campaigns, will use streamulator forecasted impressions for initial budget calculation
   */
  useEffect(() => {
    /**
     * Skip for non-paced campaigns
     */
    if (!campaign?.pacing) return;

    const itemsInitialized = new Set<string>(Object.keys(budgets));

    /**
     * Saves calculation time, current budget has same number of initialized items as total number of draftItems,
     * No need to calculate further, all items have been initialized once.
     */
    if (itemsInitialized.size === draftItems.length) return;

    const totalItems = new Set<string>(draftItems.map((item) => item.uuid));
    const missingItems = totalItems.difference(itemsInitialized);

    const loadedItems = new Set<string>(
      Object.keys(maxImpressionsState.maxImpressions).map((showUUID) => showToItemUUID[showUUID])
    );
    const itemsReadyForInitialize = missingItems.intersection(loadedItems);

    const draftItemMap = draftItems.reduce(
      (accu, curr) => {
        accu[curr.uuid] = { ...curr };
        return accu;
      },
      {} as { [campaignItemUUID: string]: ICampaignItem }
    );

    if (itemsReadyForInitialize.size > 0) {
      const initialItemBudgets = [...itemsReadyForInitialize].reduce(
        (acc, campaignItemUUID) => {
          const draftItem = draftItemMap[campaignItemUUID];
          const { showUUID, totalBudget } = draftItem;
          const publicShow = publicShows[showUUID];

          const impressions = maxImpressionsState?.maxImpressions?.[showUUID]?.impressions;

          const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem: draftItem });
          const budget = getBudget({ cpm, impressions });

          acc[campaignItemUUID] =
            typeof totalBudget === "number" && totalBudget > 0
              ? totalBudget
              : typeof budget === "number"
                ? budget
                : 0;
          return acc;
        },
        {} as { [campaignItemUUID: string]: number }
      );
      setBudgets((prev) => {
        return { ...prev, ...initialItemBudgets };
      });
    }
  }, [budgets, draftItems, maxImpressionsState.loadingShowUUIDs.size]);

  /**
   * For non paced campaigns, reset budgets based on invalid end dates/ lack of end dates forecasting/sold out shows
   */
  useEffect(() => {
    const atLeastOneShowSoldOutShow = Object.entries(minEndDatesState.minEndDates ?? {})
      .map(([_, val]) => val.estimatedEndTime)
      .some((estimatedEndDate) => {
        return typeof estimatedEndDate === "number" && estimatedEndDate < 0;
      });

    if (
      !minEndDatesState.isLoading &&
      !campaign?.pacing &&
      atLeastOneShowSoldOutShow &&
      !didReset
    ) {
      const draftItemMap = draftItems.reduce(
        (accu, curr) => {
          accu[curr.uuid] = { ...curr };
          return accu;
        },
        {} as { [campaignItemUUID: string]: ICampaignItem }
      );
      const newZeroBudgets = Object.entries(minEndDatesState.minEndDates).reduce(
        (accu, curr) => {
          const [showUUID, val] = curr;
          const { estimatedEndTime } = val;

          const campaignItemUUID = showToItemUUID?.[showUUID];
          const draftItem = draftItemMap[campaignItemUUID];
          const { totalBudget } = draftItem;

          if (
            typeof estimatedEndTime === "number" &&
            estimatedEndTime < 0 &&
            isUUID(campaignItemUUID) &&
            (typeof totalBudget !== "number" ||
              (typeof totalBudget === "number" && totalBudget <= 0))
          ) {
            accu[campaignItemUUID] = 0;
          }

          return accu;
        },
        {} as { [campaignItemUUID: string]: number }
      );

      setDidReset(true); // Only reset on initial load.
      setBudgets((prevBudgets) => {
        const newBudgets = { ...prevBudgets, ...newZeroBudgets };
        return newBudgets;
      });
    }
  }, [minEndDatesState.isLoading, !campaign?.pacing, didReset, draftItems]);

  // SCRIPTS STATE =================================================================================
  const {
    scriptsByCampaignUUID,
    scriptsByUUID,
    isLoading: isScriptsLoading,
  } = useSelectorTS((state) => state?.scripts);
  const scripts = (campaign && scriptsByCampaignUUID[campaign.uuid]) || [];
  const [scriptState, setScriptState] = useState<ScriptForm[]>([]);
  const [scriptAssignmentState, setScriptAssignmentState] = useState<LocalScriptAssignments>(null);

  useEffect(() => {
    const fetchScripts = async (campaignUUID: string) =>
      await dispatch(getScriptsForCampaign(campaignUUID));
    if (campaignUUID) fetchScripts(campaignUUID);
  }, [campaignUUID]);

  useEffect(() => {
    if (campaign && scripts) {
      setScriptState(
        getAddScriptInitialState({
          campaign,
          scripts,
          type: "editAll",
          initialize: "empty",
        })
      );
    }
  }, [campaign, scripts?.length]);

  const initialAssignedState = calcInitialValues({
    items: draftItems,
    publicShows,
    scriptsByUUID,
  });

  // Initially sync assigned script local state
  useEffect(() => {
    if (scriptAssignmentState === null && initialAssignedState !== null) {
      // Only fires once to remove initial state of null
      setScriptAssignmentState(initialAssignedState);
    }
  }, [initialAssignedState, scriptAssignmentState]);

  useEffect(() => {
    if (!isScriptsLoading) setScriptAssignmentState(initialAssignedState);
  }, [isScriptsLoading]);

  // PAYMENTS STATE ================================================================================
  const [invoicingStyle, setInvoicingStyle] = useState<ICampaign["invoicingStyle"]>();
  const [invoicingMonthType, setInvoicingMonthType] = useState<ICampaign["invoicingMonthType"]>();

  // default to credit card
  const [paymentMethodType, setPaymentMethodType] = useState<CampaignPaymentType | undefined>(
    CreditCardPaymentMethod
  );
  const [paymentCardId, setPaymentCardId] = useState<string | undefined>(undefined);

  // paymentMethodType = invoicing
  // means that invoicingStyle can be full_amount/monthly and invoicingMonthType is ignored
  // paymentMethodType = credit_card
  // means that invoicingStyle should be monthly and invoicingMonthType can be calendar/broadcast
  useEffect(() => {
    const defaultPaymentType = user.invoicingPaymentEnabled
      ? InvoicingPaymentMethod
      : CreditCardPaymentMethod;
    const paymentType = campaign?.paymentType || defaultPaymentType;
    setPaymentMethodType(paymentType);

    const defaultInvoicingStyle =
      paymentType === InvoicingPaymentMethod
        ? CampaignInvoicingStyleFull
        : CampaignInvoicingStyleMonthly;
    const invoicingStyle =
      paymentType === CreditCardPaymentMethod
        ? CampaignInvoicingStyleMonthly
        : campaign?.invoicingStyle || defaultInvoicingStyle;
    setInvoicingStyle(invoicingStyle);

    const defaultInvoicingMonthType = CampaignInvoicingMonthTypeCalendar;
    const invoicingMonthType = campaign?.invoicingMonthType || defaultInvoicingMonthType;
    setInvoicingMonthType(invoicingMonthType);
  }, [campaign, user]);

  return (
    <CampaignSchedulerContext.Provider
      value={{
        // ALL STATE
        campaignUUID,
        campaign,
        campaignItems,
        excludedCampaignItems,

        // SCHEDULER STATE
        budgets,
        setBudgets,
        timeline,
        setTimeline,
        deadlines,
        setDeadlines,
        minEndDatesState,
        maxImpressionsState,
        isSchedulerLoading: isCampaignsLoading || isCampaignItemsLoading,

        // SCRIPTS STATE
        scripts,
        scriptState,
        setScriptState,
        scriptAssignmentState,
        setScriptAssignmentState,

        // PAYMENTS STATE
        invoicingStyle,
        setInvoicingStyle,
        invoicingMonthType,
        setInvoicingMonthType,
        paymentMethodType,
        setPaymentMethodType,
        paymentCardId,
        setPaymentCardId,
      }}>
      {children}
    </CampaignSchedulerContext.Provider>
  );
};
