import { useEffect, useMemo, useRef } from "react";
import { useDispatchTS, useSelectorTS } from "./redux-ts";
import { getTransactionsForUser } from "src/action_managers/transactions";
import { isUUID } from "src/lib/uuid";
import { ICampaignItem } from "redcircle-types";
import { isCampaignItemAccepted } from "src/lib/campaigns";
import objectHash from "object-hash";

/**
 * Helper hook to calculate paid/unpaid data from accepted campaign items
 * @param {ICampaignItem[]}
 */
export const useCalcCampaignItemPaidData = (items: ICampaignItem[] | undefined = []) => {
  const dispatch = useDispatchTS();
  const { isLoading, userTransactions, initialFetched } = useSelectorTS(
    (state) => state.transactions
  );

  /**
   * Load transactions
   */
  useEffect(() => {
    /**
     * Only fetch transactions if there are V2 items (might save some load time until full migration)
     */
    if (!isLoading && !initialFetched) {
      dispatch(getTransactionsForUser());
    }
  }, [isLoading, initialFetched]);

  const { totalPaidAmount, totalUnpaidAmount } = useMemo(() => {
    // Only use accepted campaign Items
    const acceptedItems = items.filter((item) => isCampaignItemAccepted(item));

    // Separate campaign Items by version
    const { v1Items, v2Items } = acceptedItems.reduce(
      (accu, item) => {
        if (item.isV2) {
          accu.v2Items.push(structuredClone(item));
        } else {
          accu.v1Items.push(structuredClone(item));
        }

        return accu;
      },
      { v1Items: [], v2Items: [] } as { v1Items: ICampaignItem[]; v2Items: ICampaignItem[] }
    );

    // mark all v2 campaigns
    const v2CampaignsMap = v2Items.reduce(
      (accu, curr) => {
        const { campaignUUID } = curr;
        if (!accu[campaignUUID]) {
          accu[campaignUUID] = true;
        }

        return accu;
      },
      {} as { [campaignUUID: string]: boolean }
    );

    // get all v2 campaign transactions
    const v2CampaignTransactions = userTransactions?.filter(
      (transaction) => isUUID(transaction.campaignUUID) && v2CampaignsMap[transaction.campaignUUID]
    );

    /**
     * Calculate V1 Paid and UnPaid amounts in cents
     */
    const v1PaidAmount = v1Items.reduce((sum: number, campaignItem: ICampaignItem) => {
      const totalCents = campaignItem.paidUpToAmount || 0.0;
      const paidOutCents =
        totalCents - Math.floor((totalCents * campaignItem.advertisingCutBasisPoints) / 10000);
      return sum + paidOutCents;
    }, 0);

    const v1UnpaidAmount = v1Items.reduce((sum: number, campaignItem: ICampaignItem) => {
      const totalCents = campaignItem.paidUpToAmount || 0.0;
      const paidOutCents =
        totalCents - Math.floor((totalCents * campaignItem.advertisingCutBasisPoints) / 10000);
      /**
       *  Due to conservative rounding, projected unpaid amount can be off a few cents, going
       *  to min floor it at zero to avoid negative values since it never makes sense to have
       * negative projected earnings.
       */
      const unpaidCents = Math.max(campaignItem.totalCreatorAmount - paidOutCents, 0);
      return sum + unpaidCents;
    }, 0);

    /**
     * Calculate v2 Paid and UnPaid amounts in cents
     */

    const v2PaidAmount = v2CampaignTransactions
      .filter((transaction) => transaction.type === "CreatorPayout")
      .reduce((accu, curr) => {
        accu = accu + Math.abs(curr.amount) / 1000; // turn millicents -> cents
        return accu;
      }, 0);

    const projectedV2TotalAmount = v2Items.reduce((accu, item) => {
      const totalInCents = (item?.budget?.total ?? 0) / 1000;
      const creatorCut = (10000 - item.advertisingCutBasisPoints) / 10000;
      const creatorTotalInCent = totalInCents * creatorCut;

      accu = accu + creatorTotalInCent;

      return accu;
    }, 0);

    const v2UnpaidAmount = projectedV2TotalAmount - v2PaidAmount;

    const totalPaidAmount = v1PaidAmount + v2PaidAmount;
    const totalUnpaidAmount = v1UnpaidAmount + v2UnpaidAmount;

    return {
      totalPaidAmount,
      totalUnpaidAmount,
    };
  }, [objectHash(userTransactions), objectHash(items)]);

  return { totalPaidAmount, totalUnpaidAmount, isLoading };
};
