import { Alert, Form, Tooltip } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { ICampaign } from "redcircle-types";
import { COLORS, Divider, Loading } from "redcircle-ui";
import { useGetCampaignItemReforecast } from "src/hooks/campaign_items";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";

export default function ReforecastAlert({
  campaign,
  campaignItems,
}: {
  campaign?: ICampaign;
  campaignItems: Record<string, ICampaignItem>;
}) {
  const form = Form.useFormInstance();
  const formTimeline = Form.useWatch("timeline", { form, preserve: true });
  const formStartDate = formTimeline?.[0]?.unix();
  const formCPM = Form.useWatch("cpm", { form, preserve: true });
  const formTotalBudget = Form.useWatch("totalBudget", { form, preserve: true });

  const campaignItemUUIDs = useMemo(
    () => campaignItems && Object.values(campaignItems).map((item) => item.uuid),
    [campaignItems]
  );

  const budgets = useSelectorTS((state) => state.budgets);

  const campaignStatsByUUID = useSelectorTS((state) => state.campaigns.campaignStatsByUUID);
  const campaignStats = campaign && campaignStatsByUUID?.[campaign.uuid];

  const publicShows = useSelectorTS((state) => state.publicShows);
  const {
    data: reforecastData,
    isLoading,
    error: errorMessage,
  } = useGetCampaignItemReforecast(campaignItemUUIDs, form);

  const data = useMemo(() => {
    if (!reforecastData) return null;
    return Object.keys(reforecastData)
      .map((uuid) => {
        const campaignItem = campaignItems[uuid];
        const show = campaignItem && publicShows[campaignItem.showUUID];

        const campaignItemCPM =
          getAverageCPM({ campaign, campaignItem, show, when: "final" }) / 100;
        const campaignItemImp =
          (campaignItem && campaignStats?.downloadsByShowUUID?.[campaignItem.showUUID]) ?? 0;
        const campaignItemBudget = campaignItem?.budgetUUID
          ? budgets[campaignItem.budgetUUID]
          : null;
        const campaignItemCalculatedSpend = (campaignItemCPM * campaignItemImp) / 1000;
        const campaignItemSpend = campaignItemBudget
          ? campaignItemBudget.current / 1000 / 100
          : campaignItemCalculatedSpend;

        if (!campaignItem || !show || !campaignItemCPM) return null;

        const impressionsByPosition = reforecastData?.[uuid]?.impressionsByPosition ?? {};
        const forecastedImpressions = Object.values(impressionsByPosition)?.reduce(
          (a: number, c: number) => a + c,
          0
        ) as number;

        const totalBudget = formTotalBudget || campaignItem.totalBudget / 100;
        const cpm = formCPM || campaignItemCPM;
        const totalPossibleSpend = (forecastedImpressions * cpm) / 1000 + campaignItemSpend;
        return {
          uuid,
          show,
          endDate: reforecastData?.[uuid]?.endDate,
          totalPossibleSpend,
          totalBudget,
          percentage: totalPossibleSpend > 0 ? (totalBudget / totalPossibleSpend) * 100 : 0,
        };
      })
      .filter((x) => x && x !== null)
      .sort((a: any, b: any) => {
        if (a?.show.title && b?.show.title) {
          return a.show.title.localeCompare(b.show.title);
        }
      });
  }, [reforecastData, publicShows, campaign, campaignItems, formCPM, formTotalBudget]);

  const maxPercentage = data && Math.max(...data.map((x) => x?.percentage || 0));
  const error = maxPercentage && maxPercentage > 101;

  return (
    <Alert
      message={
        <>
          <div className="flex-row-container align-center">
            <h5>Sizing</h5>
            <Tooltip title="Total budgeted spend over maximum available spend for all selected campaign items, including any modifications">
              <AiFillInfoCircle className="m-lxxxs" />
            </Tooltip>
          </div>
          <Divider className="m-yxxxs" />
          {isLoading && <Loading className="m-txxs" />}
          {errorMessage && <div>{errorMessage}</div>}

          {!isLoading && data && data.length > 1 && (
            <div className="flex-row-container align-center m-bxs">
              <span
                className="m-rxxxs"
                style={{
                  color: error ? COLORS.COLOR_ERROR : "inherit",
                }}>
                <strong>Maximum Sizing:</strong> {maxPercentage?.toFixed(0)}%
              </span>{" "}
              <Tooltip title="The highest percentage of the budgeted spend over maximum forecasted spend out of all selected campaign items">
                <AiFillInfoCircle />
              </Tooltip>
            </div>
          )}

          {!isLoading && data && (
            <ul className="list-style-none p-l0 m-b0">
              {data.map((values) => {
                if (!values) return null;
                const { uuid, show, totalPossibleSpend, totalBudget, percentage, endDate } = values;
                const err = percentage > 101;
                const hasEndDate = endDate && endDate > 0;

                return (
                  <li
                    key={uuid}
                    className="flex-row-container align-center"
                    style={{
                      color: err ? COLORS.COLOR_ERROR : "inherit",
                    }}>
                    <span>
                      <strong>{show.title}</strong>: {formatMoney(totalBudget)} /{" "}
                      {formatMoney(totalPossibleSpend)} ({percentage?.toFixed(0)}%)
                      {hasEndDate &&
                        ` (forecasted end date ${dayjs.unix(endDate).format("MM/DD/YYYY")}, ${dayjs.unix(endDate).diff(dayjs.unix(formStartDate), "days")} days)`}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      }
      type="warning"
      className="m-txs"
    />
  );
}
