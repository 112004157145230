import * as Sentry from "@sentry/react";
import React from "react";
import { Col, Row } from "antd";
import LoggedOutFrame from "./lib/logged_out_frame";

const Fallback = () => {
  return (
    <LoggedOutFrame title="Whoops! Looks like something went wrong.">
      <Row>
        <Col xs={24} md={{ span: 12, offset: 6 }}>
          <div>
            An error has occurred and we're working to fix the problem! Please try again later.
          </div>
          <div className="m-ts">
            If you need immediate help don't hesitate to contact our support team at{" "}
            <a href="https://support.redcircle.com">https://support.redcircle.com</a>
          </div>
        </Col>
      </Row>
    </LoggedOutFrame>
  );
};

export default function AppErrorBoundary({ children }: { children: React.ReactNode }) {
  return <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>;
}
