import { Form, FormInstance } from "antd";
import { useEffect, useRef, useState } from "react";
import { getCampaign, reforecastCampaignItem } from "src/action_managers/campaigns";
import { isUUID } from "src/lib/uuid";
import { useDebounce } from "./lib";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

const MAX_ATTEMPTS = 5;

export const useGetCampaignItems = (campaignUUID?: string) => {
  const dispatch = useDispatchTS();
  const {
    campaignItemByCampaignUUID,
    isLoading: isCampaignItemsLoading,
    campaignItems: campaignItemsByUUID,
  } = useSelectorTS((state) => state.campaignItems);

  const campaignItemsUUIDs = campaignUUID ? campaignItemByCampaignUUID[campaignUUID] : undefined;

  const campaignItems = campaignItemsUUIDs?.map((uuid) => campaignItemsByUUID[uuid]);
  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);

  const isCampaignItemsAvailable = !!campaignItems;

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(1);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);

  // Reset fetch attempts counter on campaignUUID change
  useEffect(() => {
    failedAttempts.current = 1;
    success.current = false;
  }, [campaignUUID]);

  useEffect(() => {
    if (!isCampaignItemsLoading) {
      // Currently no other campaignsItems are being fetched
      if (isUUIDAvailable) {
        // UUID is currently available
        if (!isCampaignItemsAvailable) {
          // Campaign is not found in Redux state
          if (!success.current && failedAttempts.current < MAX_ATTEMPTS) {
            // MAX attempts have not been reached
            dispatch(getCampaign(campaignUUID))
              .then(() => (success.current = true))
              .catch(() => failedAttempts.current++);
          }
        }
      }
    }
  }, [campaignUUID, isCampaignItemsLoading, isUUIDAvailable, isCampaignItemsAvailable]);

  return campaignItems;
};

interface IReforecastData {
  [uuid: string]: {
    endDate?: number;
    impressionsByPosition: Record<string, number>;
  };
}

export const useGetCampaignItemReforecast = (
  campaignItemUUIDs: string[] = [],
  form: FormInstance
) => {
  const dispatch = useDispatchTS();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<IReforecastData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const pacing = Form.useWatch("pacing", { form, preserve: true });
  const recentEpisodesOnly = Form.useWatch("recentEpisodesOnly", { form, preserve: true });
  const frequencyConfig = Form.useWatch("frequencyConfigs", { form, preserve: true });
  const targetingOptions = Form.useWatch("targetingOptionsArray", { form, preserve: true });
  const timeline = Form.useWatch("timeline", { form, preserve: true });
  const cpm = Form.useWatch("cpm", { form, preserve: true });
  const budgetTotal = Form.useWatch("totalBudget", { form, preserve: true });
  const deboucedBudget = useDebounce(budgetTotal);

  useEffect(() => {
    const run = async () => {
      if (!campaignItemUUIDs || campaignItemUUIDs.length === 0) {
        setResults(null);
        return;
      }

      setError(null);
      setIsLoading(true);
      const responseResults = {} as IReforecastData;
      const promises = campaignItemUUIDs.map(async (uuid) => {
        if (!isUUID(uuid)) return;
        const cpmCents = cpm * 100;
        const budgetTotalMillicents = deboucedBudget * 100 * 1000;
        const startAt = timeline?.[0]?.unix();
        const endAt = timeline?.[1]?.unix();
        const transformedTargetingOptions = targetingOptions
          ? targetingOptions.reduce(
              (acc: any, curr: any) => {
                acc[curr] = true;
                return acc;
              },
              {} as Record<string, string>
            )
          : {};
        const body = {
          pacing,
          recentEpisodesOnly,
          frequencyConfig,
          targetingOptions: transformedTargetingOptions,
          startAt,
          endAt,
          hostReadPreRollCPM: cpmCents,
          hostReadMidRollCPM: cpmCents,
          hostReadPostRollCPM: cpmCents,
          budgetTotal: budgetTotalMillicents,
        };
        const res = await dispatch(reforecastCampaignItem(uuid, body));
        if (res.status === 200) responseResults[uuid] = res.json as IReforecastData[string];
        if (res.status === 500 && res.json?.message) setError(res.json.message);
      });
      await Promise.all(promises);

      setResults(responseResults);
      setIsLoading(false);
    };

    run();
  }, [
    campaignItemUUIDs,
    pacing,
    recentEpisodesOnly,
    frequencyConfig,
    targetingOptions,
    timeline,
    cpm,
    deboucedBudget,
  ]);

  return { data: results, isLoading, error };
};
