import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "redcircle-lib";
import { Button } from "redcircle-ui";

interface IProps {
  html?: string;
  lines?: number;
}

const LINE_HEIGHT = 20;

export default function ExpandableText({ lines = 4, html }: IProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(true);
  const [isExpandable, setIsExpandable] = useState<boolean | null>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (html && ref.current) {
      const isExpandable = ref.current.scrollHeight > LINE_HEIGHT * lines;
      setExpanded(false);
      setIsExpandable(isExpandable);
    }
  }, [html, ref?.current, lines, windowSize]);

  return (
    <div>
      {html && (
        <div
          className={`${expanded ? "" : `line-clamp-${lines}`}`}
          dangerouslySetInnerHTML={{ __html: html }}
          ref={ref}
        />
      )}
      {isExpandable && (
        <Button type="link" onClick={() => setExpanded(!expanded)} className="p-a0">
          Read {expanded ? "less" : "more"}
        </Button>
      )}
    </div>
  );
}
