export const distributionKeySpotify = "spotify";
export const distributionKeyApplePodcasts = "applePodcasts";
export const distributionKeyGooglePodcasts = "googlePodcasts";
export const distributionKeyStitcher = "stitcher";
export const distributionKeyTuneIn = "tuneIn";
export const distributionKeyRadioPublic = "radioPublic";
export const distributionKeyAmazonMusic = "amazonMusic";

export const DistributionToFriendlyNames = {
  [distributionKeyApplePodcasts]: "Apple Podcasts",
  [distributionKeyAmazonMusic]: "Amazon Music",
  [distributionKeySpotify]: "Spotify",
  [distributionKeyGooglePodcasts]: "Google Podcasts",
  [distributionKeyStitcher]: "Stitcher",
  [distributionKeyTuneIn]: "TuneIn",
  [distributionKeyRadioPublic]: "RadioPublic",
};
