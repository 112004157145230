import { cloneElement, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { hideModal } from "src/actions/modal";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { oneLevelUp } from "../lib/routing";
import CampaignCopyModal from "../pages/campaigns/campaign_list_page/campaign_list_page_modals";
import InsertionPointsModal from "../pages/dynamic_insertion/insertion_points_modal";
import DeclineCampaignOfferModal from "../pages/shows/decline_campaign_offer_modal";
import CampaignEditorModal from "./campaign_editor_modal";
import CampaignItemPauseModal from "./campaign_item_pause";
import CampaignItemUnPauseModal from "./campaign_item_unpause";
import CampaignSchedulerModal from "./campaign_scheduler_modal";
import CampaignSelectPodcastModal from "./campaign_select_podcast_modal/CampaignSelectPodcastModal";
import ConfirmationModalWrapper from "./confirmation_modal_wrapper";
import DealCreateModal from "./deal_create_modal";
import DealRequestModal from "./deal_request_modal";
import DirectoryShowInvite from "./directory_show_invite";
import DistributionsModalWrapper from "./distributions_modal_wrapper";
import DowngradeBlockModal from "./downgrade-modal";
import EnableSubscriptionModal from "./enable_subscriptions/enable_subscriptions";
import EpisodeDeleteModalWrapper from "./episode_delete_modal_wrapper";
import EpisodeFormModalWrapper from "./episode_form_modal_wrapper";
import ListenOnModal from "./listen_on_modal";
import MonetizationIntroModal from "./monetization_intro_modal/monetization_intro_modal";
import PixelModal from "./pixel_modal";
import { IPixelModal } from "./pixel_modal/pixel_modal";
import PostActionModal from "./post_action_modal";
import PromotionsIntroModal from "./promotions_intro_modal";
import PromotionsOptInModal from "./promotions_opt_in_modal";
import ShowFormModalWrapper from "./show_form_modal_wrapper";
import ShowRedirectHelpModalWrapper from "./show_redirect_help_modal_wrapper";
import SponsorModal from "./sponsorship_modal/sponsorship_modal";
import TipModal from "./tip_modal";
import TransferToBankModal from "./transfer_to_bank_modal";
import UploadInterimModal from "./upload_interim_modal/upload_interim_modal";
import { AudioUploadModalWrapper } from "./upload_modal_wrapper";
import VastSetupModal from "./vast_setup_modal/vast_setup_modal";
import WaitlistModal from "./waitlist_modal";
import CampaignItemCancelModal, {
  ICampaignItemCancelModal,
} from "./campaign_item_cancel/campaign_Item_cancel";
import CampaignItemUnCancelModal, {
  ICampaignItemUnCancelModal,
} from "./campaign_item_uncancel/campaign_item_uncancel";

export const UPLOAD_MEDIA_MODAL = "UPLOAD_MEDIA_MODAL";
export const EPISODE_DELETE_MODAL = "EPISODE_DELETE_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const SHOW_FORM_MODAL = "SHOW_FORM_MODAL";
export const EPISODE_FORM_MODAL = "EPISODE_FORM_MODAL";
export const SHOW_REDIRECT_HELP_MODAL = "SHOW_REDIRECT_HELP_MODAL";
export const DISTRIBUTIONS_MODAL = "DISTRIBUTIONS_MODAL";
export const PROMOTIONS_INTRO_MODAL = "PROMOTIONS_INTRO_MODAL";
export const PROMOTIONS_OPT_IN_MODAL = "PROMOTIONS_OPT_IN_MODAL";
export const DEAL_CREATE_MODAL = "DEAL_CREATE_MODAL";
export const INVITE_DIRECTORY_SHOW = "INVITE_DIRETORY_SHOW";
export const DEAL_REQUEST_MODAL = "DEAL_REQUEST_MODAL";
export const POST_ACTION_MODAL = "POST_ACTION_MODAL";
export const MONETIZATION_INTRO_MODAL = "MONETIZATION_INTRO_MODAL";
export const SPONSORSHIP_MODAL = "SPONSORSHIP_MODAL ";
export const ENABLE_SUBSCRIPTION_MODAL = "ENABLE_SUBSCRIPTION_MODAL";
export const LISTEN_ON_MODAL = "LISTEN_ON_MODAL";
export const TIP_MODAL = "TIP_MODAL";
export const CAMPAIGN_EDITOR_MODAL = "CAMPAIGN_EDITOR_MODAL";
export const CAMPAIGN_COPY_MODAL = "CAMPAIGN_COPY_MODAL";
export const SET_EPISODE_MARKERS_MODAL = "SET_EPISODE_MARKERS_MODAL";
export const CAMPAIGN_ADD_PODCAST_MODAL = "CAMPAIGN_ADD_PODCAST_MODAL";
export const CAMPAIGN_SCHEDULER_MODAL = "CAMPAIGN_SCHEDULER_MODAL";
export const UPLOAD_INTERIM = "UPLOAD_INTERIM";
export const PIXEL_MODAL = "PIXEL_MODAL";
export const DECLINE_CAMPAIGN_SURVEY = "DECLINE_CAMPAIGN_SURVEY";
export const DOWNGRADE_BLOCK_MODAL = "DOWNGRADE_BLOCK_MODAL";
export const TRANSFER_TO_BANK_MODAL = "TRANSFER_TO_BANK_MODAL";
export const VAST_SETUP_MODAL = "VAST_SETUP_MODAL";
export const WAITLIST_MODAL = "WAITLIST_MODAL";
export const CAMPAIGN_ITEM_PAUSE_MODAL = "CAMPAIGN_ITEM_PAUSE_MODAL";
export const CAMPAIGN_ITEM_UNPAUSE_MODAL = "CAMPAIGN_ITEM_UNPAUSE_MODAL";
export const CAMPAIGN_ITEM_CANCEL_MODAL = "CAMPAIGN_ITEM_CANCEL_MODAL";
export const CAMPAIGN_ITEM_UN_CANCEL_MODAL = "CAMPAIGN_ITEM_UN_CANCEL_MODAL";

export const CAMPAIGN_SCHEDULER_MODAL_OLD = "CAMPAIGN_SCHEDULER_MODAL_OLD";

const MODAL_COMPONENTS = {
  [UPLOAD_MEDIA_MODAL]: (props: any) => <AudioUploadModalWrapper {...props} />,
  [EPISODE_DELETE_MODAL]: (props: any) => <EpisodeDeleteModalWrapper {...props} />,
  [CONFIRMATION_MODAL]: (props: any) => <ConfirmationModalWrapper {...props} />,
  [DOWNGRADE_BLOCK_MODAL]: (props: any) => <DowngradeBlockModal {...props} />,
  [SHOW_FORM_MODAL]: (props: any) => <ShowFormModalWrapper {...props} />,
  [EPISODE_FORM_MODAL]: (props: any) => <EpisodeFormModalWrapper {...props} />,
  [SHOW_REDIRECT_HELP_MODAL]: (props: any) => <ShowRedirectHelpModalWrapper {...props} />,
  [DISTRIBUTIONS_MODAL]: (props: any) => <DistributionsModalWrapper {...props} />,
  [PROMOTIONS_INTRO_MODAL]: (props: any) => <PromotionsIntroModal {...props} />,
  [PROMOTIONS_OPT_IN_MODAL]: (props: any) => <PromotionsOptInModal {...props} />,
  [DEAL_CREATE_MODAL]: (props: any) => <DealCreateModal {...props} />,
  [DEAL_REQUEST_MODAL]: (props: any) => <DealRequestModal {...props} />,
  [INVITE_DIRECTORY_SHOW]: (props: any) => <DirectoryShowInvite {...props} />,
  [POST_ACTION_MODAL]: (props: any) => <PostActionModal {...props} />,
  [MONETIZATION_INTRO_MODAL]: (props: any) => <MonetizationIntroModal {...props} />,
  [SPONSORSHIP_MODAL]: (props: any) => <SponsorModal {...props} />,
  [ENABLE_SUBSCRIPTION_MODAL]: (props: any) => <EnableSubscriptionModal {...props} />,
  [LISTEN_ON_MODAL]: (props: any) => <ListenOnModal {...props} />,
  [TIP_MODAL]: (props: any) => <TipModal {...props} />,
  [SET_EPISODE_MARKERS_MODAL]: (props: any) => <InsertionPointsModal {...props} />,
  [TRANSFER_TO_BANK_MODAL]: (props: any) => <TransferToBankModal {...props} />,
  [CAMPAIGN_EDITOR_MODAL]: (props: any) => <CampaignEditorModal {...props} />,
  [CAMPAIGN_COPY_MODAL]: (props: any) => <CampaignCopyModal {...props} />,
  [CAMPAIGN_ADD_PODCAST_MODAL]: (props: any) => <CampaignSelectPodcastModal {...props} />,
  [CAMPAIGN_SCHEDULER_MODAL]: (props: any) => <CampaignSchedulerModal {...props} />,
  [UPLOAD_INTERIM]: (props: any) => <UploadInterimModal {...props} />,
  [PIXEL_MODAL]: (props: IPixelModal) => (
    <ModalAdapter modalType="PIXEL_MODAL">
      <PixelModal {...props} />
    </ModalAdapter>
  ),
  [DECLINE_CAMPAIGN_SURVEY]: (props: any) => <DeclineCampaignOfferModal {...props} />,
  [VAST_SETUP_MODAL]: (props: any) => <VastSetupModal {...props} />,
  [WAITLIST_MODAL]: (props: any) => <WaitlistModal {...props} />,
  [CAMPAIGN_ITEM_PAUSE_MODAL]: (props: any) => <CampaignItemPauseModal {...props} />,
  [CAMPAIGN_ITEM_UNPAUSE_MODAL]: (props: any) => <CampaignItemUnPauseModal {...props} />,
  [CAMPAIGN_ITEM_CANCEL_MODAL]: (props: ICampaignItemCancelModal) => (
    <ModalAdapter modalType="CAMPAIGN_ITEM_CANCEL_MODAL">
      <CampaignItemCancelModal {...props} />
    </ModalAdapter>
  ),
  [CAMPAIGN_ITEM_UN_CANCEL_MODAL]: (props: ICampaignItemUnCancelModal) => (
    <ModalAdapter modalType="CAMPAIGN_ITEM_UN_CANCEL_MODAL">
      <CampaignItemUnCancelModal {...props} />
    </ModalAdapter>
  ),
};

export type ModalType = keyof typeof MODAL_COMPONENTS;

export type ReduxModalProps = {
  closeRoute: () => void;
  closeModal: () => {
    type: string;
  };
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
  match: ReturnType<typeof useRouteMatch>;
  params: Record<string, string> | Record<string, never>;
  modalType: string | null;
  /**
   * Added via Modal Adapter which can hook up directly to Antd Modal component props
   */
  open: boolean;
  onClose: () => void;
  afterClose: () => void;
};

const ModalRoot = () => {
  const { modalType, modalProps } = useSelectorTS((state) => state.modal);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const params = match.params;

  /**
   * There are 2 ways to use these modals with modal Root,
   *  1. Use ModalLauncher and enable the modal via a route change
   *  2. Use showModal() redux dispatch.
   *
   * Going to copy all the default modal props of Modal Launcher to the showModal redux dispatch props
   */
  const combinedModalProps: Omit<ReduxModalProps, "open" | "onClose" | "afterClose"> = {
    closeRoute: () => history.push(oneLevelUp(location)),
    closeModal: () => dispatch(hideModal()),
    location,
    history,
    match,
    params,
    modalType,
    ...modalProps,
  };

  if (!modalType) return null;

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...combinedModalProps} />;
};

/**
 * Abstracts logic to better controls close/afterClose functionality
 */
interface IModalAdapter {
  children: ReactElement;
  modalType: ModalType;
}

const ModalAdapter = ({ modalType, children }: IModalAdapter) => {
  const dispatch = useDispatchTS();
  const [open, setOpen] = useState(true); // Used for better onClose modal animation control
  const modal = useSelectorTS((state) => state.modal);

  const openModalFlag = modal.modalType === modalType;

  /**
   * Sync Modal open flag with app redux modal state, only on open
   */
  useEffect(() => {
    if (openModalFlag) {
      setOpen(openModalFlag);
    }
  }, [openModalFlag]);

  /**
   * Manually close the modal
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   * On close animation complete, remove redux modal state
   */
  const afterClose = () => {
    dispatch(hideModal());
  };

  return cloneElement(children, { open, onClose, afterClose });
};

export default ModalRoot;
