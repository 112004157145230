import { Alert, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "redcircle-ui";
import { useDispatchTS } from "src/hooks/redux-ts";
import { usePublicShow } from "src/hooks/shows";
import { overrideCampaignItem, unCancelCampaignItem } from "src/action_managers/campaigns";
import { showError, showSuccess } from "src/actions/app";
import { ReduxModalProps } from "../modal_root";
import { ICampaign, ICampaignItem, isCampaign, isCampaignItem } from "redcircle-types";
import FlightingDateForm from "../campaign_editor_modal/flighting_date_form";
import {
  initializeCampaignDeadlines,
  initializeCampaignTimeline,
} from "../campaign_scheduler_modal/campaign_scheduler_utils";
import dayjs from "dayjs";
import {
  diffRequestFromCampaignItem,
  transformFormFieldsToOverrideRequest,
} from "src/lib/overrides";

export interface ICampaignItemUnCancelModal extends ReduxModalProps {
  campaignItem: ICampaignItem;
  campaign: ICampaign;
}

/**
 * Displays Campaign Item Pause Modal
 */
export const CampaignItemUnCancelModal = ({
  campaignItem,
  campaign,
  open,
  onClose,
  afterClose,
}: ICampaignItemUnCancelModal) => {
  const [form] = Form.useForm<ReturnType<typeof initializeFormFields>>();
  const fields = Form.useWatch([], { form, preserve: true });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatchTS();

  const { show } = usePublicShow(campaignItem.showUUID);
  const showTitle = show.title;

  const isPostSend = !!campaignItem.lifecycleSettings?.sendTask?.completed;

  /**
   * Initialize values
   */
  useEffect(() => {
    if (isCampaign(campaign) && isCampaignItem(campaignItem)) {
      form.resetFields();

      const newFields = initializeFormFields({
        campaign,
        campaignItem,
      });

      form.setFieldsValue(newFields);
    }
  }, [campaign, campaignItem]);

  const handleSubmit = async () => {
    try {
      const fields = form.getFieldsValue(true);

      const request = transformFormFieldsToOverrideRequest(fields);
      const overrideRequest = diffRequestFromCampaignItem(request, campaignItem);

      setIsSubmitting(true);

      /**
       * Update campaign item time lines first
       */
      const resp = await dispatch(
        overrideCampaignItem({ campaignItemUUID: campaignItem.uuid, request: overrideRequest })
      );

      if (resp.status !== 200) {
        if (typeof resp?.json?.message === "string") {
          await dispatch(showError(resp?.json?.message ?? ""));
        } else {
          dispatch(
            showError(
              "Could not restore this line item. Please try again, if the problem persists contact us at RedCircle support."
            )
          );
        }

        setIsSubmitting(false);
        /**
         * end it here since no point in un-canceling line item with faulty deadlines
         */
        return;
      }

      /**
       * Un-cancel campaign item
       */
      const resp2 = await dispatch(unCancelCampaignItem({ campaignItemUUID: campaignItem.uuid }));

      switch (resp2.status) {
        case 200:
          dispatch(showSuccess(`Successfully restored the line item for ${showTitle}`));
          break;
        case 400:
          dispatch(showError(resp2?.json?.message ?? ""));
          break;
        default:
          dispatch(
            showError(
              "Could not restore this line item. Please try again, if the problem persists contact us at RedCircle support."
            )
          );
      }

      onClose();
    } catch (err) {
      dispatch(
        showError(
          "Could not restore this line item. Please try again, if the problem persists contact us at RedCircle support."
        )
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        timeline: [],
        deadlines: {
          assignAudioDeadline: undefined,
          responseDeadline: undefined,
          pacing: true,
        },
      }}>
      <Modal
        size="sm"
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        afterClose={afterClose}>
        <Modal.Title>Restore canceled line item</Modal.Title>
        <Modal.Body>
          <Row>
            <Col xs={24}>
              <h5>
                This action will restore the canceled line item for this campaign. You can modify
                its dates and details afterward. Are you sure?{" "}
              </h5>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer noBorder>
          <Modal.CloseButton>Close</Modal.CloseButton>
          <Modal.SubmitButton isLoading={isSubmitting}>Restore</Modal.SubmitButton>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

const initializeFormFields = ({
  campaign,
  campaignItem,
}: {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
}) => {
  const timeline = initializeCampaignTimeline(campaign);
  const deadlines = initializeCampaignDeadlines(campaign);

  const fields = {
    timeline:
      campaignItem.lifecycleSettings.startAt.value > 0 &&
      campaignItem.lifecycleSettings.endAt.value > 0
        ? [
            dayjs.unix(campaignItem.lifecycleSettings.startAt.value),
            dayjs.unix(campaignItem.lifecycleSettings.endAt.value),
          ]
        : timeline,
    deadlines: {
      assignAudioDeadline:
        campaignItem.lifecycleSettings.assignAudioTask?.deadline.value > 0
          ? dayjs.unix(campaignItem.lifecycleSettings.assignAudioTask?.deadline.value)
          : deadlines.assignAudioDeadline,
      responseDeadline:
        campaignItem.lifecycleSettings.responseTask?.deadline.value > 0
          ? dayjs.unix(campaignItem.lifecycleSettings.responseTask?.deadline.value)
          : deadlines.responseDeadline,
    },
    pacing: campaignItem.pacing?.value,
  };

  return fields;
};

const validateFormFields = ({
  fields,
  campaignItem,
}: {
  fields: ReturnType<typeof initializeFormFields>;
  campaignItem: ICampaignItem;
}) => {
  let canSubmit = true;
  const validationMessages: string[] = [];

  const now = dayjs();

  const { responseTask, assignAudioTask } = campaignItem.lifecycleSettings;

  const [startDate, endDate] = fields?.timeline ?? [];
  const { assignAudioDeadline, responseDeadline } = fields?.deadlines || {};

  /**
   * Check if start date needs to be updated
   */
  if (!!startDate && now.isAfter(startDate)) {
    canSubmit = false;
    validationMessages.push("Line item start date is required to be a future date.");
  }

  /**
   * Check if response deadline date needs to be updated
   */
  if (!responseTask.completed && (!responseDeadline || now.isAfter(responseDeadline))) {
    canSubmit = false;
    validationMessages.push("Line item response deadline is required to be a future date.");
  }

  /**
   * Check if assigned audio deadline date needs to be updated
   */
  if (!assignAudioTask.completed && (!assignAudioDeadline || now.isAfter(assignAudioDeadline))) {
    canSubmit = false;
    validationMessages.push("Line item assigned audio deadline is required to be a future date.");
  }

  /**
   * Check if end date needs to be updated
   */
  if (
    campaignItem.pacing.value &&
    (!endDate ||
      endDate.isBefore(startDate) ||
      endDate.isBefore(responseDeadline) ||
      endDate.isBefore(assignAudioDeadline) ||
      endDate.isBefore(now))
  ) {
    canSubmit = false;
    validationMessages.push("Line item end date is required to be a future date.");
  }

  return { canSubmit, validationMessages };
};

export default CampaignItemUnCancelModal;
