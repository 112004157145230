import dayjs from "dayjs";
import { ICampaign } from "redcircle-types";
import {
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
} from "src/constants/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";

export const getCampaignItemField = (
  field: keyof ICampaignItem | "endAt" | "pixelRequired",
  { campaignItem, campaign }: { campaignItem: ICampaignItem; campaign?: ICampaign }
): any => {
  // these fields have been replaced by overridable fields
  switch (field) {
    case "startAt":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.startAt?.value
        : campaignItem?.startAt;
    case "endAt":
      return campaignItem.isV2 ? campaignItem?.lifecycleSettings?.endAt?.value : campaign?.endsAt;
    case "frequencyConfigs":
      return campaignItem.isV2 ? campaignItem?.frequencyConfigs?.value : campaign?.frequencyConfigs;
    case "hardEndDate":
      return campaignItem.isV2 ? campaignItem?.hardEndDate?.value : campaign?.hardEndDate;
    case "pacing":
      return campaignItem.isV2 ? campaignItem?.pacing?.value : campaign?.pacing;
    case "requiresEndorsement":
      return campaignItem.isV2
        ? campaignItem?.requiresEndorsement?.value
        : campaign?.requiresEndorsement;
    case "pixelRequired":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.pixelRequired?.value
        : campaign?.pixelRequired;
    case "targetingOptions":
      return campaignItem.isV2 ? campaignItem?.targetingOptions?.value : campaign?.targetingOptions;
    case "recentEpisodesOnly":
      return campaignItem.isV2
        ? campaignItem?.recentEpisodesOnly?.value
        : campaign?.recentEpisodesOnly;
    default:
      return campaignItem[field];
  }
};

const OVERESTIMATION_CUTOFF_DAYS = 28;
const OVERESTIMATION_PERCENTAGE = 0.5;

export const isCampaignItemUnderdelivering = ({
  campaignItem,
  campaign,
}: {
  campaignItem: ICampaignItem;
  campaign?: ICampaign;
}) => {
  if (
    [
      CampaignItemStateDraft,
      CampaignItemStateCompleted,
      CampaignItemStateDeclined,
      CampaignItemStateExpired,
    ].includes(campaignItem.state)
  ) {
    return false;
  }

  const forecastData = campaignItem.forecast;
  const pacing = getCampaignItemField("pacing", { campaignItem });
  const hardEndDate = getCampaignItemField("hardEndDate", { campaignItem, campaign });
  let isUnderdelivering = false;

  if (pacing && hardEndDate && forecastData?.unspentBudget && forecastData.unspentBudget > 0) {
    isUnderdelivering = true;
  }

  if (pacing && !hardEndDate) {
    const estimatedEndDate =
      forecastData?.endDate && forecastData.endDate > 0 ? dayjs.unix(forecastData.endDate) : null;
    const endAt = getCampaignItemField("endAt", { campaignItem });
    const targetEndDate = endAt ? dayjs.unix(endAt) : null;
    const startAt = getCampaignItemField("startAt", { campaignItem });
    const targetStartDate = startAt ? dayjs.unix(startAt) : null;

    if (estimatedEndDate && targetEndDate && targetStartDate) {
      const overestimation = estimatedEndDate.diff(targetEndDate, "days");
      const campaignLength = targetEndDate.diff(targetStartDate, "days");

      if (
        overestimation > campaignLength * OVERESTIMATION_PERCENTAGE ||
        overestimation > OVERESTIMATION_CUTOFF_DAYS
      ) {
        isUnderdelivering = true;
      }
    }
  }

  return isUnderdelivering;
};
