import { captureException } from "@sentry/react";
import { SelectProps } from "antd";
import { isUndefined } from "lodash";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import size from "lodash/size";
import moment, { Moment } from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { fetchStatOptions } from "../../../actions/stats_options";
import { getManualPayoutsFromUser } from "../../../action_managers/manual_payouts";
import { getEpisodeByShow, ShowsForUserActionManager } from "../../../action_managers/shows";
import { fetchSubscriptionsByShowUUID } from "../../../action_managers/subscriptions";
import { getTransactionsForUser } from "../../../action_managers/transactions";
import showsApi from "../../../api/shows";
import { ADVERTISING_OPT_IN_THRESHOLD } from "../../../constants/misc";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import {
  shouldShowTierAContentOnly,
  shouldShowTierBContentOnly,
  shouldShowTierCContentOnly,
  shouldShowTierDContentOnly,
  shouldShowTierOGContentOnly,
  shouldShowTierPerm,
} from "../../../lib/feature_flag";
import { episodeByShowType } from "../../../reducers/episode_by_show/episode_by_show";
import { IShow } from "redcircle-types";
import { tierLevel } from "../../../reducers/types";

// constants

export const downloadsOverviewRequestPrefix = "Downloads-Overview" as const;
export const downloadsSectionRequestPrefix = "Downloads" as const;
export const episodesPerformanceRequestPrefix = "Episode-Performance" as const;
export const GeoLocationPerformanceRequestPrefix = "Geo-Performance" as const;
export const exclusiveDownloadsRequestPrefix = "Exclusive-Downloads" as const;
export const exclusiveSubscribersRequestPrefix = "Exclusive-Subscribers" as const;
export const podcastPerformanceRequestPrefix = "Podcast-Performance" as const;
export const timeMapRequestPrefix = "TimeMap" as const;
export const insertionOverviewRequestPrefix = "Insertions-Overview" as const;
export const insertionRequestPrefix = "Insertions-Section" as const;
export const insertionTopEpisodesPrefix = "Insertions-Top-Episodes" as const;

export const companyStartDate = moment("2018-04-15").tz(moment.tz.guess());
export const ALL_PODCASTS = "All Podcasts" as const;
export const ALL_EPISODES = "All Episodes" as const;
export const ALL_CAMPAIGNS = "All Campaigns" as const;
export const ALL_AUDIO_BLOCKS = "All Audio Blocks" as const;
export const ALL_CUSTOM_AUDIO = "All Custom Audio" as const;
export const alignment = ["left", "center", "right"] as const;

export const graphFonts = 'Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif';

export const defaultAllPodcastsCutoff = 5;

// Types
export type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

export const DatePresets = [
  "Last 24 Hours",
  "Last 48 Hours",
  "Last 7 Days",
  "Last 30 Days",
  "Last 60 Days",
  "Last 90 Days",
  "Last 12 Months",
  "Year to Date",
  "All Time",
] as const;

export const ShortPresetMap: Record<string, (typeof DatePresets)[number]> = {
  "24H": DatePresets[0],
  "7D": DatePresets[2],
  "30D": DatePresets[3],
  "60D": DatePresets[4],
  "90D": DatePresets[5],
  YTD: DatePresets[7],
  All: DatePresets[8],
};

export const LongPresetMap = {
  [DatePresets[0]]: "24H",
  [DatePresets[2]]: "7D",
  [DatePresets[3]]: "30D",
  [DatePresets[4]]: "60D",
  [DatePresets[5]]: "90D",
  [DatePresets[7]]: "YTD",
  [DatePresets[8]]: "All",
};

// Request Response stats, filter
export interface RequestResponseDataPoint {
  status: number;
  json: Array<{
    date: number;
    count: number;
    pathValues: string[];
  }>;
}

export interface RequestResponseScalarDataPoint {
  status: number;
  json: {
    count: number;
  };
}

export type StatsRequestFilter =
  | {
      isUnique?: boolean;
      isProgrammatic?: boolean;
      sumTerm?: string;
      campaignStyle?: "hostRead" | "campaignAudio";
      arbitraryTimeRange: string;
      timezone?: string;
      interval?: "1h" | "1d" | "1w" | "1M" | "1y" | "1q";
      showUUID?: string;
      episodeUUID?: string;
      bucketTerms?: string;
      type?: string;
    }
  | {
      isUnique?: boolean;
      isProgrammatic?: boolean;
      sumTerm?: string;
      campaignStyle?: "hostRead" | "campaignAudio";
      timeRange: "allTime" | "year" | "month" | "week" | "day";
      timezone?: string;
      showUUID?: string;
      episodeUUID?: string;
      bucketTerms?: string;
      type?: string;
      campaignUUID?: string;
      interval?: "1h" | "1d" | "1w" | "1M" | "1y" | "1q";
    };

// Graph and Data Types
interface GenericGraphDataPoint {
  date: number;
  count: number;
  episode: string;
  color?: string;
}
export type DownloadSectionGraphDataPoint = Pick<GenericGraphDataPoint, "date" | "count">;

export interface EpisodeSectionGraphDataPoint {
  date: number;
  count: number;
  episode: string;
  title?: string;
  color?: string;
}
export interface EpisodeSectionTableDataPoint {
  key: string;
  episodeUUID: string;
  episodeTitle: string;
  showTitle: string;
  showUUID: string;
  publishedDate: number;
  downloads: number;
  color?: string;
  showRedirectedAt?: number;
  "1_day": number;
  "2_day": number;
  "3_day": number;
  "4_day": number;
  "5_day": number;
  "6_day": number;
  "7_day": number;
  "30_day": number;
  "60_day": number;
  "90_day": number;
}
export type EpisodeSectionAggregateTimeIntervals = keyof Omit<
  EpisodeSectionTableDataPoint,
  | "key"
  | "episodeUUID"
  | "episodeTitle"
  | "showTitle"
  | "publishedDate"
  | "downloads"
  | "color"
  | "showRedirectedAt"
  | "showUUID"
>;

export const allowedTimeIntervals: EpisodeSectionAggregateTimeIntervals[] = [
  "1_day",
  "2_day",
  "3_day",
  "4_day",
  "5_day",
  "6_day",
  "7_day",
  "30_day",
  "60_day",
  "90_day",
];

export interface ListeningCardGraphData {
  key: string;
  type: string;
  percent: string;
  value: number;
  total: number;
}

export interface PodcastPerformanceGraphDataPoint {
  date: number;
  count: number;
  show: string;
  showUUID: string;
  color?: string;
}
export interface PodcastPerformanceTableDataPoint {
  key: string;
  showTitle: string;
  showUUID: string;
  downloads: number;
  percent: string;
  color?: string;
}

export interface TimeMapDatPoint {
  day: string;
  hour: string;
  downloads: number;
}

export type ExclusiveDownloadsGraphDataPoint = Pick<GenericGraphDataPoint, "date" | "count">;
export type ExclusiveSubscribersGraphDataPoint = Pick<GenericGraphDataPoint, "date" | "count">;

export const DropdownIntervalValues = ["Hour", "Day", "Week", "Month", "Quarter", "Year"] as const;

export const graphRedColor = "#FF9CA0";

// Graph types
export const RevenueGraphTypes = [
  "All Time Earnings",
  "RAP Host-Read",
  "RAP Programmatic",
  "Subscriptions",
  "Donations",
  "Other",
] as const;

export const InsertionGraphTypes = [
  "Total Dynamic Insertions",
  "RAP Host-Read",
  "RAP Programmatic",
  "Cross Promotions",
  "Other Dynamic Insertions",
] as const;

// Error/Info Messages

export const DataIsLoadingMessage = "Graph data is loading. Please try again in a few seconds";

// SHARED UTILITY FUNCTIONS

export const middleEllipses = (text: string, maxLength = 35, endLength = 8) => {
  if (text?.length > maxLength && endLength < maxLength) {
    text = text?.slice(0, maxLength - endLength) + "..." + text?.slice(endLength * -1);
  }

  return text;
};

// Helper to get aggregate total from an array of Data Points
export const getTotalFromData = <T extends { date: number; count: number }>(
  data: T[],
  startValue = 0
) =>
  data?.reduce((accu, curr) => {
    return (accu += curr.count);
  }, startValue);

// Helper for dynamic graph title
export const getDurationTextFromDates = (dateRange: [Moment, Moment]) => {
  const start = dateRange?.[0]?.isValid() ? dateRange[0]?.clone() : moment();
  const end = dateRange?.[1]?.isValid() ? dateRange[1]?.clone() : moment();

  const duration = moment.duration(end?.diff(start));

  const hours = Math.round(duration.asHours());
  const days = Math.round(duration.asDays());
  const months = Math.round(duration.asMonths());
  const daysSinceYearStarted = Math.round(
    moment.duration(moment().diff(moment().startOf("year"))).asDays()
  );

  const endOfToday = moment().endOf("day");
  const endDateIsToday = moment().format("MM/DD/YYYY") === end.format("MM/DD/YYYY");
  const endDateIsEndOfToday = endOfToday.isSame(end);

  const presetUsed = endDateIsToday && !endDateIsEndOfToday;

  if (!presetUsed) {
    return `from ${dateRange?.[0]?.format("MMM D, YYYY")} - ${dateRange?.[1]?.format(
      "MMM D, YYYY"
    )}`;
  }

  // Logic for determining graph granulatrity options
  if (hours === 24 || hours === 48) {
    return `in the Last ${hours} Hours`;
  } else if (days === daysSinceYearStarted) {
    return `this year`;
  } else if (days === 7 || days === 30 || days === 60 || days === 90) {
    return `in the Last ${days} Days`;
  } else if (months === 12 && days >= 360 && days <= 369) {
    return `in the Last ${months} Months`;
  } else {
    return `from ${dateRange?.[0]?.format("MMM D, YYYY")} - ${dateRange?.[1]?.format(
      "MMM D, YYYY"
    )}`;
  }
};

export const dateFormatStringDiff = "MM/DD/YYYY hh a";

export const isPresetDates = (
  dates: [Moment, Moment],
  Preset: Record<(typeof DatePresets)[number], [Moment, Moment]>
) => {
  const [start, end] = dates;

  return Object.entries(Preset)?.some(([, value]) => {
    const [presetStart, presetEnd] = value;

    return presetStart.valueOf() === start.valueOf() && presetEnd.valueOf() === end.valueOf();
  });
};

export const yAxisLabelFormatter = (val: string | number) => {
  if (typeof val === "string") {
    val = parseFloat(val);
  }

  const absVal = Math.abs(val);

  if (absVal > 999999) {
    return numeral(val).format("0.00a");
  }
  if (absVal > 99999) {
    return numeral(val).format("000.0a");
  }
  if (absVal > 9999) {
    return numeral(val).format("00.0a");
  }
  if (absVal >= 1) {
    return numeral(val).format("0,0");
  }
  return numeral(val).format("0[.]0");
};

export const formatNumberValue = (num: number | string) => {
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  const absNum = Math.abs(num);

  if (absNum < 9999) {
    return numeral(num).format("0,0");
  } else if (absNum >= 10000 && absNum < 100000) {
    return numeral(num).format("0.0a");
  } else {
    return numeral(num).format("0.0[0]a");
  }
};

// Get endpoint interval from Date Interval
export const getIntervalFromDates = (dateRange: [Moment, Moment]) => {
  const [start, end] = dateRange;
  const duration = moment.duration(end?.diff(start));

  const hours = duration.asHours();
  const days = duration.asDays();

  // Logic for determining graph granulatrity options
  if (hours <= 48) {
    return "1h";
  } else {
    if (days <= 7) {
      return "1d";
    } else if (days <= 30) {
      return "1d";
    } else if (days <= 90) {
      return "1w";
    } else if (days <= 364) {
      return "1M";
    } else {
      return "1y";
    }
  }
};

// Get endpoint interval from selected dropdown option
export const getIntervalFromDropdown = (option: (typeof DropdownIntervalValues)[number]) => {
  switch (option.toUpperCase()) {
    case "HOUR":
      return "1h";
    case "DAY":
      return "1d";
    case "WEEK":
      return "1w";
    case "MONTH":
      return "1M";
    case "QUARTER":
      return "1q";
    case "YEAR":
      return "1y";
    default:
      return "1d";
  }
};

export const getAllTimeEndDate = (
  dateRange: [Moment, Moment],
  interval: ReturnType<typeof getIntervalFromDropdown>
) => {
  let start = moment();

  if (dateRange?.[0]?.isValid()) {
    start = dateRange[0]?.clone();
  }

  switch (interval) {
    case "1h":
      start.startOf("hour");
      return start.unix() - 1;
    case "1d":
      start.startOf("day");
      return start.unix() - 1;
    case "1w":
      start.startOf("isoWeek");
      return start.unix() - 1;
    case "1M":
      start.startOf("month");
      return start.unix() - 1;
    case "1q":
      start.startOf("quarter");
      return start.unix() - 1;
    case "1y":
      start.startOf("year");
      return start.unix() - 1;
  }
};

// Based on dateRange , get all dropdown options
export const getDropdownOptionsFromDates: (
  dateRange: [Moment, Moment]
) => Array<(typeof DropdownIntervalValues)[number]> = (dateRange: [Moment, Moment]) => {
  const [start, end] = dateRange;
  const duration = moment.duration(end?.diff(start));

  const hours = duration.asHours();
  const days = duration.asDays();

  // Logic for determining graph granulatrity options
  if (hours <= 48) {
    return ["Hour"];
  } else {
    if (days <= 7) {
      return ["Hour", "Day"];
    } else if (days <= 30) {
      return ["Day", "Week"];
    } else if (days <= 90) {
      return ["Day", "Week"];
    } else if (days <= 365) {
      return ["Week", "Month"];
    } else {
      return ["Month", "Quarter", "Year"];
    }
  }
};

// Based on dateRange , get default dropdown option
export const getDropdownDefaultValueFromDates = (dateRange: [Moment, Moment]) => {
  const [start, end] = dateRange;
  const duration = moment.duration(end?.diff(start));

  const hours = duration.asHours();
  const days = duration.asDays();

  // Logic for determining graph granulatrity options
  if (hours <= 48) {
    return "Hour";
  } else {
    if (days <= 7) {
      return "Day";
    } else if (days <= 30) {
      return "Day";
    } else if (days <= 90) {
      return "Week";
    } else if (days <= 365) {
      return "Month";
    } else {
      return "Quarter";
    }
  }
};

// Get X Axis date mask based on selected dropdown option
export const getMaskFromDropdown = (option: (typeof DropdownIntervalValues)[number]) => {
  switch (option.toUpperCase()) {
    case "HOUR":
      return "ddd hh a";
    case "DAY":
      return "MMM DD";
    case "WEEK":
      return "MMM DD";
    case "MONTH":
      return "MMM YYYY";
    case "QUARTER":
      return "MMM YYYY";
    case "YEAR":
      return "YYYY";
    default:
      return "MMM DD";
  }
};

export const tooltipTitleFactory = (interval: ReturnType<typeof getIntervalFromDropdown>) => {
  return (title: string | any, datum: Record<string, any>) => {
    const isString = typeof datum?.date === "string";
    if (isString) {
      return datum?.date;
    }

    switch (interval) {
      case "1h":
        return moment(datum?.date).format("hh:mm:ss a");
      case "1d":
        return moment(datum?.date).format("MMM DD, YYYY");
      case "1w": {
        const date = moment(datum?.date).format("MMM DD, YYYY");
        return `Week of ${date}`;
      }
      case "1M":
        return moment(datum?.date).format("MMM YYYY");
      case "1q": {
        const start = moment(datum.date).startOf("quarter").format("MMM");
        const end = moment(datum.date).endOf("quarter").format("MMM");
        const year = moment(datum.date).format("YYYY");
        return `${start} - ${end} ${year}`;
      }
      case "1y":
        return moment(datum?.date).format("YYYY");
      default:
        return moment(datum?.date).format("MMM DD, YYYY");
    }
  };
};

export const reportFormatDateFromInterval = (
  interval: ReturnType<typeof getIntervalFromDropdown>,
  date: number
) => {
  switch (interval) {
    case "1h":
      return moment(date).format("hh:mm:ss a");
    case "1d":
      return moment(date).format("MMMM DD, YYYY");
    case "1w": {
      const formattedDate = moment(date).format("MMMM DD, YYYY");
      return `Week of ${formattedDate}`;
    }
    case "1M":
      return moment(date).format("MMMM YYYY");
    case "1q": {
      const start = moment(date).startOf("quarter").format("MMM");
      const end = moment(date).endOf("quarter").format("MMM");
      const year = moment(date).format("YYYY");
      return `${start} - ${end} ${year}`;
    }
    case "1y":
      return moment(date).format("YYYY");
    default:
      return moment(date).format("MMMM DD, YYYY");
  }
};

export const getListOfEpisodes = (selectedShow: string, episodesByShow: episodeByShowType) => {
  if (selectedShow === ALL_PODCASTS) {
    return [];
  }

  const episodes = episodesByShow?.[selectedShow]?.episodes;

  if (isEmpty(episodes)) {
    return [];
  }

  const now = moment().unix();

  const result: Array<{ uuid: string; title: string; isVisible?: boolean }> = Object.keys(episodes)
    .map((epUUID) => {
      const { uuid, title, isVisible, publishedAt } = episodes[epUUID];
      return {
        uuid,
        title: title as string,
        isVisible,
        publishedAt,
      };
    })
    .filter((ep) => ep.isVisible && ep.publishedAt <= now)
    .reverse();

  result.unshift({
    uuid: ALL_EPISODES,
    title: ALL_EPISODES,
  });

  return result;
};

export const getEpisodeTitleSuffix = (
  selectedShow: string,
  selectedEpisode: string,
  episodesByShow: episodeByShowType
) => {
  if (selectedEpisode === ALL_EPISODES) {
    return "";
  }

  if (isUndefined(episodesByShow?.[selectedShow]?.episodes?.[selectedEpisode]?.title)) {
    return "";
  }

  return `_${episodesByShow?.[selectedShow]?.episodes?.[selectedEpisode]?.title}`;
};

export const blankIfZero = (n: number) => (n === 0 ? "" : n);

// Utility function used to identify each data request
export const getRequestHash = (
  prefix: string,
  selectedItem: string,
  dateRange: [Moment, Moment],
  dropdownVal?: (typeof DropdownIntervalValues)[number]
) => {
  // Request Unique ID will be based on the date of the date ranges + interval
  return `${prefix}-${selectedItem}-${dateRange?.[0]?.format(
    dateFormatStringDiff
  )}-${dateRange?.[1]?.format(dateFormatStringDiff)}${dropdownVal ? "-" + dropdownVal : ""}`;
};

export const equalityFunc = <
  T extends { dateRange: [Moment, Moment]; timeZone: string; selectedShow: string },
>(
  prevProps: T,
  nextProps: T
) => {
  const dateRangeIsSame =
    moment(prevProps?.dateRange[0]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[0]).format(dateFormatStringDiff) &&
    moment(prevProps?.dateRange[1]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[1]).format(dateFormatStringDiff);

  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;

  return dateRangeIsSame && timeZoneIsSame && selectShowIsSame;
};

export const customTooltip = (title: any, data: any) => {
  return `
    <div class="analyticsPage-GraphTooltip">
      <span class="analyticsPage-GraphTooltip--title">${title}</span>
        ${data
          ?.map((item: any) => {
            return `
              <div class="analyticsPage-GraphTooltip--item"> 
                <span style="background-color:${item?.color}"> </span>  
                <span>${yAxisLabelFormatter(item?.data?.count)}</span>
              </div>`;
          })
          .join("")}
    </div>`;
};

// Filter search function for podcast name dropdown

export const handleFilterOptions: SelectProps["filterOption"] = (input, option) => {
  return ((option?.children as unknown as string) || "")
    .trim()
    .toLowerCase()
    .includes(input?.trim()?.toLowerCase());
};

// Filter Sort function for podcast name dropdowns

export const handleFilterSort: SelectProps["filterSort"] = (a, b) => {
  const optionA = (a?.children as unknown as string)?.trim()?.toLowerCase() || "";
  const optionB = (b?.children as unknown as string)?.trim()?.toLowerCase() || "";

  if (optionA === ALL_PODCASTS.toLowerCase()) return -1;
  if (optionB === ALL_PODCASTS.toLowerCase()) return 1;

  return optionA.localeCompare(optionB);
};

// Config

export const paginationConfig = {
  position: ["bottomCenter"] as Array<"bottomCenter">,
  showSizeChanger: false,
  hideOnSinglePage: true,
  responsive: true,
};

// Utility function to determine is user has a show that has programmatic Enabled

export const someProgrammaticEnabled = (shows: Record<string, IShow>) => {
  return Object?.keys(shows)?.some((showUUID) => isProgrammaticEnabled(shows[showUUID]));
};

export const isHostReadEnabled = (show: IShow) =>
  Boolean(show?.advertisementSettings?.isHostReadEnabled);

export const isProgrammaticEnabled = (show: IShow) =>
  Boolean(show?.programmaticAdSettings?.enabled);

export const isExclusiveContentEnabled = (show: IShow) =>
  Boolean(show?.subscriptionDetails?.exclusiveContentEnabled);

export const isDonationsEnabled = (show: IShow) =>
  Boolean(show?.subscriptionDetails?.recurringDonationsEnabled);
export const isCrossPromoEnabled = (show: IShow) => Boolean(show?.canShareAudienceSize);

export const canOptInToRAP = (show: IShow) => {
  const showIsTooNew =
    show?.bucketedAudienceSize === "" || show?.bucketedAudienceSize === "calculating";
  const showIsTooSmall = show?.estimatedWeeklyDownloads < ADVERTISING_OPT_IN_THRESHOLD;

  return !(showIsTooNew || showIsTooSmall);
};

// SHARED HOOKS

// this is shared state for all components using initial data request hook,
// this ensures initial redux data is loaded once
// TODO refactor to better implmentation of singleton.

let showRequestMade = false;
let episodesRequestMade = false;
let subscriptionRequestMade = false;
let statsOptionsRequestMade = false;
let transactionsRequestMade = false;
let manualPayoutsRequestMade = false;

// Handles fetch user shows request, provides a boolean when it is loaded.
export const useShowRequest = () => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state.user.user);
  const { shows, isLoading: isLoadingShows } = useSelectorTS((state) => state?.shows);
  const showIsLoaded = !isEmpty(shows) || !isLoadingShows;

  useEffect(() => {
    if (!showRequestMade && isEmpty(shows) && !isLoadingShows) {
      showRequestMade = true;
      dispatch(new ShowsForUserActionManager(user).run());
    }
  }, [isEmpty(shows), isLoadingShows]);

  return [showIsLoaded];
};

export const useInitialDataRequest = () => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state.user.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const isLoadingShows = useSelectorTS((state) => state?.shows?.isLoading);
  const episodesByShow = useSelectorTS((state) => state?.episodesByShow);

  const allEpisodesLoaded = size(shows) === size(episodesByShow) - 1;
  const showIsLoaded = !isEmpty(shows) && !isLoadingShows;
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!showRequestMade && isEmpty(shows) && !isLoadingShows) {
      showRequestMade = true;
      dispatch(new ShowsForUserActionManager(user).run());
    }
    if (!episodesRequestMade && !isEmpty(shows) && !allEpisodesLoaded) {
      episodesRequestMade = true;
      Object.keys(shows).map((show) => dispatch(getEpisodeByShow(show)));
    }

    if (!isEmpty(shows) && allEpisodesLoaded) {
      setInitialDataLoaded(true);
    }
  }, [isEmpty(shows), isLoadingShows, allEpisodesLoaded]);

  return [showIsLoaded, allEpisodesLoaded, initialDataLoaded];
};

export const useSharedSubscriptionRequest = () => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state.user.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const isLoadingShows = useSelectorTS((state) => state?.shows?.isLoading);
  const subscriptionsByShowUUID = useSelectorTS(
    (state) => state?.subscriptions?.subscriptionsByShowUUIDs
  );
  const showIsLoaded = !isEmpty(shows) && !isLoadingShows;
  const showsWithSubscriptionEnabled = filter(shows, (show) => !!show?.subscriptionDetails?.amount);
  const allShowSubscriptionsLoaded =
    showIsLoaded && showsWithSubscriptionEnabled.length === size(subscriptionsByShowUUID);

  useEffect(() => {
    if (!showRequestMade && isEmpty(shows) && !isLoadingShows) {
      showRequestMade = true;
      dispatch(new ShowsForUserActionManager(user).run());
    }

    if (!subscriptionRequestMade && showIsLoaded && !allShowSubscriptionsLoaded) {
      subscriptionRequestMade = true;
      showsWithSubscriptionEnabled.map(({ uuid }) =>
        dispatch(fetchSubscriptionsByShowUUID({ showUUID: uuid }))
      );
    }
  }, [showIsLoaded, allShowSubscriptionsLoaded]);

  return [showIsLoaded, allShowSubscriptionsLoaded];
};

export const useSharedFetchStatsOption = () => {
  const dispatch = useDispatchTS();
  const statsoptionsIsLoading = useSelectorTS((state) => state?.statsOptions?.isLoading);
  const statsOptions = useSelectorTS((state) => state?.statsOptions);

  const statsOptionsIsLoaded =
    statsOptions?.os.length !== 0 &&
    statsOptions?.device.length !== 0 &&
    statsOptions?.app.length !== 0;

  useEffect(() => {
    if (!statsOptionsRequestMade && !statsOptionsIsLoaded && !statsoptionsIsLoading) {
      statsOptionsRequestMade = true;
      dispatch(fetchStatOptions());
    }
  }, [statsOptionsIsLoaded]);

  return [statsOptionsIsLoaded];
};

export const useSharedRevenueRequests = () => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state?.user?.user);
  const { isLoading: manualPayoutsLoading, manualPayouts: manualPayoutsArray } = useSelectorTS(
    (state) => state?.manualPayouts
  );
  const { userTransactions, isLoading: transactionsLoading } = useSelectorTS(
    (state) => state?.transactions
  );

  const userTransactionsIsLoaded =
    userTransactions?.length > 0 || (transactionsRequestMade && !transactionsLoading);
  const manualPayoutsIsLoaded =
    manualPayoutsArray?.length > 0 || (manualPayoutsRequestMade && !manualPayoutsLoading);
  const initialDataIsLoaded = userTransactionsIsLoaded && manualPayoutsIsLoaded;

  // Handles fetching transactions and payouts data, using set timeout to debounce effect hook.
  // limiting  data reqeust to 1 request per multiple sequential renders (mostly on initial render)
  useEffect(() => {
    if (!transactionsRequestMade && !userTransactionsIsLoaded) {
      transactionsRequestMade = true;
      dispatch(getTransactionsForUser());
    }

    if (!manualPayoutsRequestMade && !manualPayoutsIsLoaded) {
      manualPayoutsRequestMade = true;
      dispatch(getManualPayoutsFromUser(user.uuid));
    }
  }, [userTransactionsIsLoaded, manualPayoutsIsLoaded]);

  return [userTransactionsIsLoaded, manualPayoutsIsLoaded, initialDataIsLoaded];
};

// Helper hook to grab all user shows , including deleted shows, since deleted shows are removed
// from redux store state object. This is needed to show analytics info on deleted shows such as
// revenue for a deleted show within a specific time range when it was available.
export const useFetchAllShows = () => {
  const user = useSelectorTS((state) => state?.user?.user);
  const [isLoading, setIsLoading] = useState(true);
  const [allShows, setAllShows] = useState<{ [uuid: string]: IShow }>({});

  useEffect(() => {
    if (user) {
      isLoading || setIsLoading(true); // if isLoading is false set it to true
      showsApi
        .fetchShowsForUser(user)
        .then((resp: any) => resp.json())
        .then((response: IShow[]) => {
          if (Array.isArray(response)) {
            const showsMap = keyBy(response, "uuid");
            setAllShows(showsMap);
          }
          isLoading && setIsLoading(false);
        })
        .catch((err: any) => {
          isLoading && setIsLoading(false);
          captureException(err);
        });
    } else {
      isLoading && setIsLoading(false);
    }
  }, [isEmpty(user)]);

  return { isLoading, allShows };
};

// TIER LOGIC
export const useTierAnalyticsPerm = () => {
  const { tier } = useSelectorTS((state) => state?.user);

  const userIs = {
    TIERA: shouldShowTierPerm()
      ? tier.level === tierLevel.core
      : Boolean(shouldShowTierAContentOnly()),
    TIERB: shouldShowTierPerm()
      ? tier.level === tierLevel.growth
      : Boolean(shouldShowTierBContentOnly()),
    TIERC: shouldShowTierPerm()
      ? tier.level === tierLevel.pro
      : Boolean(shouldShowTierCContentOnly()),
    TIERD: shouldShowTierPerm()
      ? tier.level === tierLevel.enterprise
      : Boolean(shouldShowTierDContentOnly()),
    TIEROG: shouldShowTierPerm()
      ? tier.level === tierLevel.og
      : Boolean(shouldShowTierOGContentOnly()), // Normal stats with tier Widgets
    PreTierLaunchOverrideAllow: shouldShowTierPerm() ? false : true, // Is normal Stats without Tier widgets
  };

  const tierPermission = {
    General: {
      AggregationAllowed:
        userIs.TIERC || userIs.TIERD || userIs.PreTierLaunchOverrideAllow || userIs.TIEROG,
      DatePickerTimeRanges: true, // True regardless of Tier for now
      ExportDataPageLevel: false,
      ExportDataWidgetLevel:
        userIs.TIERB ||
        userIs.TIERC ||
        userIs.TIERD ||
        userIs.PreTierLaunchOverrideAllow ||
        userIs.TIEROG,
    },
    Downloads: {
      Tab: true,
      Overview: true,
      DownloadsWidget: true,
      EpisodePerformance:
        userIs.TIERB ||
        userIs.TIERC ||
        userIs.TIERD ||
        userIs.PreTierLaunchOverrideAllow ||
        userIs.TIEROG,
      PodcastPerformance: userIs.TIERC || userIs.TIERD,
      DownloadTimeOfDay: userIs.TIERB || userIs.TIERC || userIs.TIERD,
      GeolocationPerformance: {
        Widget:
          userIs.TIERB ||
          userIs.TIERC ||
          userIs.TIERD ||
          userIs.PreTierLaunchOverrideAllow ||
          userIs.TIEROG,
        allOptions: userIs.TIERC || userIs.TIERD,
      },
      ExclusiveDownloads: userIs.TIERB || userIs.TIERC || userIs.TIERD,
      ListeningApp: true,
      ListeningDevices: true,
      ListeningBrowser: true,
      QuickAccess: true,
    },
    Revenue: {
      Tab: true,
      Overview: true,
      TotalRevenue: userIs.TIERC || userIs.TIERD,
      HostRead: userIs.TIERC || userIs.TIERD,
      Programmatic:
        userIs.TIERC || userIs.TIERD || userIs.PreTierLaunchOverrideAllow || userIs.TIEROG,
      Subscriptions: userIs.TIERC || userIs.TIERD,
      Donations: userIs.TIERC || userIs.TIERD,
      Other: userIs.TIERC || userIs.TIERD,
    },
    Insertions: {
      Tab: userIs.TIERC || userIs.TIERD || userIs.PreTierLaunchOverrideAllow || userIs.TIEROG,
      Overview: userIs.TIERC || userIs.TIERD,
      TabContent: userIs.TIERC || userIs.TIERD,
      totalInsertions: userIs.TIERC || userIs.TIERD,
      HostRead: userIs.TIERC || userIs.TIERD,
      Programmatic:
        userIs.TIERC || userIs.TIERD || userIs.PreTierLaunchOverrideAllow || userIs.TIEROG,
      CrossPromotions: userIs.TIERC || userIs.TIERD,
      Other: userIs.TIERD,
      ProgrammaticEpisodes: userIs.TIERC || userIs.TIERD || userIs.PreTierLaunchOverrideAllow,
    },
    //  Flags for Launch,
    Extra: {
      showExtraWidgets: shouldShowTierPerm(),
    },
  };

  return tierPermission;
};

export const sortNameAlphabetical = <T extends { name: string }>(a: T, b: T) =>
  a?.name?.localeCompare(b?.name);
