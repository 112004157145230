import { Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { Modal } from "redcircle-ui";
import { useDispatchTS } from "src/hooks/redux-ts";
import { usePublicShow } from "src/hooks/shows";
import { cancelCampaignItem } from "src/action_managers/campaigns";
import { showError, showSuccess } from "src/actions/app";
import { ReduxModalProps } from "../modal_root";
import { ICampaignItem } from "redcircle-types";

export interface ICampaignItemCancelModal extends ReduxModalProps {
  campaignItem: ICampaignItem;
}

/**
 * Displays Campaign Item Pause Modal
 */
const CampaignItemCancelModal = ({
  campaignItem,
  open,
  onClose,
  afterClose,
}: ICampaignItemCancelModal) => {
  const dispatch = useDispatchTS();
  const [reason, setReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { show } = usePublicShow(campaignItem.showUUID);
  const showTitle = show.title;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const resp = await dispatch(
        cancelCampaignItem({ campaignItemUUID: campaignItem.uuid, reason })
      );

      switch (resp.status) {
        case 200:
          dispatch(showSuccess(`Successfully canceled the line item for ${showTitle}`));
          break;
        case 400:
          dispatch(showError(resp?.json?.message ?? ""));
          break;
        default:
          dispatch(
            showError(
              "Could not cancel this line item. Please try again, if the problem persists contact us at RedCircle support."
            )
          );
      }
      onClose();
    } catch (_) {
      dispatch(
        showError(
          "Could not cancel this line item. Please try again, if the problem persists contact us at RedCircle support."
        )
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal size="sm" open={open} onClose={onClose} onSubmit={handleSubmit} afterClose={afterClose}>
      <Modal.Title>Cancel line item</Modal.Title>
      <Modal.Body>
        <Row>
          <Col xs={24}>
            <p>This action will cancel the line item for this campaign. Are you sure? </p>
            <span className="redcircle-form-label">Reason</span>
            <Form.Item name="reason" rules={[{ required: true }]}>
              <Input.TextArea
                onChange={(e) => {
                  setReason(e?.target?.value ?? "");
                }}
                value={reason}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer noBorder>
        <Modal.CloseButton>Close</Modal.CloseButton>
        <Modal.SubmitButton isLoading={isSubmitting}>Cancel</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignItemCancelModal;
