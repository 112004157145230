import { Budget } from "./budgets";
import { FrequencyCapConfig, ICampaignTargetingOptions } from "./campaign";
import { UnixTimeStamp } from "./dates";
import { IShow } from "./show";

export type CampaignItemsReduxState = {
  campaignItemByCampaignUUID: { [key: string]: string[] };
  campaignItems: { [key: string]: ICampaignItem };
  showCampaignItemUUIDs: { [key: string]: string[] };
  isLoading: boolean;
  audioSwapReqIsLoading: boolean;
};

export interface IFlightConfig {
  weekStartAt: string;
  targetEpisodeCountInTimeRange: number;
  assignedEpisodeUUIDs: { [key: string]: string };
  invoicedEpisodeUUIDs?: { [key: string]: Record<string, unknown> };
}

export interface IOfferRates {
  enabled: boolean;
  finalAdjustedMidRollCPM: number;
  finalAdjustedPostRollCPM: number;
  finalAdjustedPreRollCPM: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  finalAdjustedSpotRate: number;
  originalMidRollCPM: number;
  originalPostRollCPM: number;
  originalPreRollCPM: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  originalSpotRate: number;
}

// CampaignItemStateDraft represents a campaign item type in draft state
export type CampaignItemState =
  | "vetting" // this is a FE only state, vetting is actually a separately tracked entity
  | "draft"
  | "sent"
  | "running"
  | "paused"
  | "awaiting-audio-upload"
  | "audio-swap-requested"
  | "accepted"
  | "declined"
  | "completed"
  | "expired"
  | "awaiting-script"
  | "none"
  | "canceled";

type TCampaignItemForecast = {
  endDate: UnixTimeStamp;
  impressions: {
    preRoll: number;
    midRoll: number;
    postRoll: number;
  };
  unspentBudget: number; // millicents
};

/**
 * TODO Build Mapper/Accessor functions for V1 <-> V2 campaign Items when accessing deprecated properties
 * TODO Go through code base and replace instances of using deprecated property
 */
export interface ICampaignItem {
  uuid: string;
  campaignUUID: string;
  campaignName: string;
  /**
   * @deprecated No longer used for V2 campaign Items
   */
  shoppingCartUUID?: string;
  creatorUUID: string;
  showUUID: string;
  budgetUUID?: string;
  adUUID?: string;
  mediaFileUUID?: string;
  /**
   * @deprecated No longer storing invoicing state in V2 campaign items
   */
  paidUpToAmount: number;
  /**
   * @deprecated No longer storing invoicing state in V2 campaign items
   */
  paidOutAt?: number;
  /**
   * @deprecated No longer storing invoicing state in V2 campaign items
   */
  invoicedUpToAmount: number;

  /**
   * total budget in cents i.e (10000 => $100) or (25000 => $250)
   * totalBudget must be a whole number
   */
  totalBudget: number;
  /**
   * @deprecated 	Deprecated: because the cut can be changed mid-campaign, this can't be calculated statically.
   * totalCreatorAmount is the portion of the total budget that we promise to the creator, after RedCircle's cut
   */
  totalCreatorAmount: number; // Value is in cents. i.e (10000 => $100) or (25000 => $250)
  /**
   * @deprecated No longer storing invoicing state in V2 campaign items
   */
  invoicedUpToCreatorAmount: number;
  /**
   * @deprecated
   * @see LifecycleSettings.startAt
   */
  startAt: number; // Campaign Start Date
  /**
   * 	@deprecated
   *  @see {LifecycleSettings.responseTask.deadline}
   * respondByAt is the deadline that's given to a creator for responding to a campaign item.
   * The campaign item will expire after this date.
   */
  respondByAt?: number; // Show Response Due Date
  /**
   * @deprecated
   * @see {LifecycleSettings.responseTask.firstCompletedAt}
   */
  respondedAt?: number;

  /**
   * @deprecated
   *
   * pacingEstimatedEndAt is the target date for the pacing optimizer. For a v1
   * campaign, it is also the date that hard end uses. For a v2 campaign, it
   * has no bearing on the campaign's end - it's just the target @see LifecycleSettings.endsAt.
   */
  pacingEstimatedEndAt?: number; // On paced campaigns, campaign Item pacing estimated end date
  /**
   * State is derived from the lifecycle settings of the campaign item, by the backend
   */
  state: CampaignItemState;
  /**
   *  @deprecated: completion happens per-campaign in v2.
   */
  completedAt?: number;
  createdAt: number;
  updatedAt: number;
  /**
   * @deprecated
   * @see LifecycleSettings.assignAudioTask.deadline
   */
  uploadAudioBy?: number; // Audio Due Date
  /**
   * @deprecated No script due date in V2
   */
  scriptDueBy?: number; // Script Due Date
  /**
   * @deprecated 	 no credit card campaigns anymore
   *  TransactionUUID for the Auth on the advertisers stripe account
   */
  transactionUUID?: string;
  /**
   * @deprecated
   * @see LifecycleSettings.sendTask.firstCompletedAt
   */
  sentAt: number; // Shopping Cart Sent Date
  promoCode?: string;
  redemptionCodes: string[];
  advertisingCutBasisPoints: number;
  flightConfigs: IFlightConfig[];
  /**
   * @deprecated no longer exists
   */
  spotRate: number;
  webhookURL: string;
  /**
   * @deprecated
   * @see LifecycleSettings.response.declinedReason
   */
  declinedReason: string;
  offerRates: IOfferRates;
  show?: IShow;
  /**
   * @deprecated
   * @see LifecycleSettings.SwapAudioPending and related
   */
  swapAudioInfo: {
    initiatorUserUUID: string;
    requestedAt: number;
    reason: string;
  };
  /**
   * @deprecated
   *
   */
  audioLastUploadedAt: number;
  budget?: Budget;
  /**
   * @deprecated: no such thing as expiry in v2.
   */
  expiredAt?: number;

  scriptUUID?: string;
  feedCTA?: string;

  /**
   * V2 Campaign Items properties
   */

  isV2?: boolean;

  lifecycleSettings: LifecycleSettings;

  response?: {
    accepted: boolean;
    declinedReason: string;
  };

  pacing: CampaignItemOverrideValue<boolean>;
  recentEpisodesOnly: CampaignItemOverrideValue<boolean>;
  hardEndDate: CampaignItemOverrideValue<boolean>;
  frequencyConfigs: CampaignItemOverrideValue<FrequencyCapConfig[]>;
  requiresEndorsement: CampaignItemOverrideValue<boolean>;
  targetingOptions: CampaignItemOverrideValue<ICampaignTargetingOptions>;
  forecast?: TCampaignItemForecast;
}

export type CampaignItemOverrideValue<T> = {
  default: T;
  value: T;
  overridden: boolean;
};
/**
 * New V2 campaign Item life cycle settings, re-using comments from backend;
 */
type LifecycleSettings = {
  sendTask: LifecycleTask;

  // ResponseTask is the mandatory deadlined task for the podcaster to respond
  // to the advertiser's offer. It can be re-completed only if the response is
  // a decline and the deadline has not passed. See item.Response.
  responseTask: LifecycleTaskWithDeadline;

  // AssignScriptTask is the mandatory task for the advertiser to provide a
  // script for the ad read. See also item.ScriptUUID.
  assignScriptTask: LifecycleTask;

  // AssignAudioTask is the mandatory deadlined task for the podcaster to
  // upload the audio of their ad read. This can be completed multiple times,
  // but must be completed at least once after the UploadScriptTask and the
  // ResponseTask are both completed but before the AssignAudioTask.Deadline.
  // See also item.MediaFileUUID.
  assignAudioTask: LifecycleTaskWithDeadline;

  // AssignPixelTask is the optional task for the advertiser to add a pixel to
  // the campaign. It can be completed at any point before the campaign start
  // date (or not at all, if PixelRequired is false). It doesn't have its own
  // deadline, but the item's start date is effectively the deadline. See
  // item.WebhookURL.
  assignPixelTask: LifecycleTask;
  // PixelRequired is true if the advertiser must provide a pixel for this
  // campaign to run.
  pixelRequired: CampaignItemOverrideValue<boolean>;

  // StartAt is the time that the campaign item is scheduled to start. If
  // zero-valued, the campaign item has not been scheduled yet.
  startAt: CampaignItemOverrideValue<UnixTimeStamp>;
  // EndAt is the time that the campaign item will stop inserting. If
  // zero-valued, the campaign item has no end date and will run until its
  // budget is fully spent. See also item.PacingEstimatedEndAt.
  endAt: CampaignItemOverrideValue<UnixTimeStamp>;

  // Paused indicates whether the campaign item is paused. If true, the
  // campaign item is paused.
  paused: boolean;
  // PausedAt is the time that the campaign item was paused. If zero-valued,
  // the campaign is not paused.
  pausedAt: UnixTimeStamp;
  // UnpausedAt is the time that the campaign item was unpaused. If
  // zero-valued, the campaign has not been unpaused, or was never paused.
  unpausedAt: UnixTimeStamp;
  // PausedReason is the reason that the campaign item was paused.
  pausedReason: string;

  // SwapAudioPending is true if we're waiting on the podcaster to swap audio
  // after a request to do so. This is distinct from Paused, and both can be
  // true simultaneously, in which case the swap must be completed AND
  // the item must be explicitly unpaused for insertions to resume.
  swapAudioPending: boolean;
  swapAudioInfo: {
    // UserUUID (so either Org UUID, or advertiser UUID) of who initiated the swap
    initiatorUserUUID: string;
    // Timestamp that this swap was requested
    requestedAt: UnixTimeStamp;
    // Reason for swapping or requesting a swap
    reason: string;
  };
  // Completed is true once the campaign item and all other campaign items in
  // the campaign are complete. Complete is the one and only terminal state for
  // a campaign item.
  completed: boolean;
  // CompletedAt is the time that the campaign item was completed. If
  // zero-valued, the campaign has not been completed.
  completedAt: UnixTimeStamp;

  // Canceled is true if the campaign item has been canceled.
  canceled: boolean;
  // CanceledAt is the time that the campaign item was canceled. If
  // zero-valued, the campaign has not been canceled.
  canceledAt: UnixTimeStamp;
  canceledReason: string;
};

type LifecycleTask = {
  completed: boolean;
  lastCompletedAt: UnixTimeStamp;
  firstCompletedAt: UnixTimeStamp;
};

type LifecycleTaskWithDeadline = LifecycleTask & {
  deadline: CampaignItemOverrideValue<UnixTimeStamp>;
};

/**
 * Type guard to check if an unknown value is a valid campaignItem object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isCampaignItem(value?: unknown): value is ICampaignItem {
  const stateValuesMapCheck: Record<CampaignItemState, true> = {
    vetting: true,
    draft: true,
    sent: true,
    running: true,
    paused: true,
    "awaiting-audio-upload": true,
    "audio-swap-requested": true,
    accepted: true,
    declined: true,
    completed: true,
    expired: true,
    "awaiting-script": true,
    none: true,
    canceled: true,
  };

  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "campaignUUID" in value &&
    "showUUID" in value &&
    "creatorUUID" in value &&
    "state" in value &&
    typeof value.uuid === "string" &&
    typeof value.state === "string" &&
    typeof value.campaignUUID === "string" &&
    typeof value.showUUID === "string"
  ) {
    return Object.keys(stateValuesMapCheck).includes(value.state);
  }

  return false;
}
